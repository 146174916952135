.pad-6
	padding: r(40px) 0
	@screen lg
		padding: r(50px) 0
.pad-8
	padding: r(40px) 0
	@screen lg
		padding: r(50px) 0 r(70px)
.tab-item
	@apply hidden
	&.active
		@apply block
.tabs-option
	@apply hidden
	&.active
		@apply block
.years-wrap
	@apply mb-4 end-item
	.custom-select
		@apply w-auto
		select
			padding-right: 50px
			@apply text-blue-0A2ECC
			font-size: r(20px)
		option
			@apply text-blue-0A2ECC
			font-size: r(20px)
.fullcontent
	@apply text-grey-333 font-normal leading-normal pt-4
	font-size: 16px
	*
		@apply text-grey-333 font-normal leading-normal
		font-size: 16px
	p
		@apply mb-3
	h2
		@apply font-bold my-3
		font-size: 24px
		*
			@apply font-bold
			font-size: 24px
	h3
		@apply font-bold my-2
		font-size: 20px
		*
			@apply font-bold
			font-size: 20px
	h4
		@apply font-bold my-2 pl-6
		font-size: 16px
		*
			@apply font-bold
			font-size: 16px
	strong
		@apply font-bold
	a
		@apply text-blue-0A2ECC
	img,iframe
		@apply block my-4 mx-auto
	ul
		@apply mb-2
		li
			@apply start-item mb-1
			&::before
				content: ''
				@apply inline-block z-10  transition  bg-blue-0A2ECC rounded-full mr-4 mt-2
				width: 6px
				height: 6px
				min-width: 6px
				@screen lg
					@apply mr-5
	.item
		@apply text-center
		.icon
			@apply mx-auto mb-3 center-item
			height: 52px
			width: auto
			img
				object-fit: contain
.top-nav-wrap
	ul
		@apply center-item
		li
			padding: 0 20px

			@screen lg
				&:hover
					a
						@apply text-blue-0A2ECC
			&.active
				a
					@apply border-blue-0A2ECC text-blue-0A2ECC
		a
			@apply text-grey-666 font-bold leading-none center-item transition  border-b  border-transparent
			font-size: r(20px)
			padding-bottom: r(14px)
.table-wrap 
	table
		thead
			th
				@apply text-white font-bold bg-blue-0A2ECC text-left border-r border-r-grey-cc
				padding: r(16px)  r(30px)
				font-size: r(16px)
				*
					font-size: r(16px)
				@media screen and ( max-width: 576px)
					padding: r(12px)  r(20px)
					font-size: r(18px)
					*
						font-size: r(18px)
		tbody
			tr
				&:nth-child(even)
					@apply bg-grey-f5
				td
					@apply  border-r border-r-grey-cc text-grey-333
					padding: r(16px)  r(30px)
					font-size: r(16px)
					a
						@apply cursor-pointer
					*
						font-size: r(16px)
						@apply text-grey-333
					@media screen and ( max-width: 576px)
						padding: r(12px)  r(20px)
						font-size: r(18px)
						*
							font-size: r(18px)
					em
						font-size: r(24px)
						@apply text-grey-666
						@media screen and ( max-width: 1024px)
							font-size: 20px
							
	@media screen and ( max-width: 1024px)
		@apply overflow-x-auto
		table
			@apply whitespace-nowrap