.financial-report-extraordinary-release
	.table-wrap 
		thead
			th
				@apply w-auto
				&:first-child
					width: 8%
				&:nth-child(2)
					width: 44%
					@media screen and ( max-width: 1024px)
						@apply whitespace-normal
						min-width: 250px
				&:nth-child(3)
					width: 32%
					@media screen and ( max-width: 1024px)
						@apply whitespace-normal
						min-width: 250px
				&:last-child
					width: 16%
					@apply text-center
				
		tbody
			td
				@apply w-auto
				&:first-child
					width: 8%
				&:nth-child(2)
					width: 44%
					@media screen and ( max-width: 1024px)
						@apply whitespace-normal
						min-width: 250px
				&:nth-child(3)
					width: 32%
					@media screen and ( max-width: 1024px)
						@apply whitespace-normal
						min-width: 250px
				&:last-child
					width: 16%
					@apply text-center
				em
					font-size: 20px