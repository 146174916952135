//Type 1 first child icon
.modulepager
	@apply flex items-center justify-center pt-8 pb-0
	@media screen and ( max-width:1024px)
	padding-bottom: 30px
	ul
		padding: 0
		margin: 0
		position: relative
		li
			display: inline-block
			padding: 0 10px
			
			&.active
				a,span
					@apply bg-blue-0A2ECC text-white
			&:hover
				a,span
					@apply underline
			span,a
				width: 40px
				height: 40px
				@apply center-item rounded-full font-bold text-grey-666 bg-grey-f5
				font-size: 20px
				@media screen and ( max-width:1024px)
					height: 32px
					width: 32px
					
