.primary-banner
	@apply relative z-10 overflow-hidden
	.banner-container
		@apply relative
		@media screen and ( max-width: 1024px)
			position: relative
			display: block
			height: 0
			overflow: hidden 
			padding-top: 805/790 *100%
			.swiper
				position: absolute
				width: 100%
				height: 100%
				top: 0
				left: 0
	.swiper
		.image-src
			@apply relative
			&:hover
				&::after
					@apply opacity-20
			&::after
				content: ''
				opacity: 0.35
				@apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full
				background: linear-gradient(37.1deg, #000000 32.43%, rgba(0, 0, 0, .38) 59.95%)
			a
				@screen lg
					display: block
					height: calc( 100vh - 100px)
					width: auto
					img,video
						width: 100%
						height: 100%
						object-fit: cover
				@media screen and ( max-width: 1024px)
					+img-ratio(805/790)
	.swiper-pagination
		@apply absolute z-50 center-item  w-auto
		left: 50% !important
		transform: translateX(-50%)
		bottom: r(60px) !important
		@media screen and ( max-width: 1024px)
			bottom: r(30px) !important
		@media screen and ( max-width: 768px)
			bottom: r(20px)
		@media screen and ( max-width: 576px)
			bottom: r(15px)
		.swiper-pagination-bullet 
			background: white
			border-radius: 0 !important
			opacity: .5
			width: r(20px)
			height: 2px
			&.swiper-pagination-bullet-active
				opacity: 1 !important
				width: r(60px)
	.title, .desc
		@apply font-normal text-center leading-tight
	.title
		color: #4fc6df
		font-size: rr(26px)
		@screen lg
			font-size: r(32px)
	.desc
		color: #0e3cdc
		font-size: r(32px)
		@screen sm
			font-size: r(40px)
		@screen lg
			font-size: r(56px)
	.txt-wrap 
		@apply absolute-x w-full px-8 z-50
		bottom: r(90px)
		@media screen and ( max-width: 1024px)
			bottom: r(60px)
		@media screen and ( max-width: 576px)
			bottom: r(40px)