.about-management
	.headline
		@apply mt-4
	.block-wrap
		&:not(:last-child)
			margin-bottom: r(40px)
			@screen lg
				margin-bottom: r(80px)
	.table-wrap
		@apply mt-4
		thead
			th
				@screen lg
					padding: r(16px)  r(60px)
				&:nth-child(2)
					@apply text-center
				&:last-child
					@apply text-right
					
		tbody
			td
				@screen lg
					padding: r(16px)  r(60px)
				&:nth-child(2)
					@apply text-center
				&:last-child
					@apply text-right
					