.careers-list
	.sub-title
		@apply font-bold leading-none text-blue-0A2ECC my-4
		font-size: r(18px)
	.filter-btn-wrap
		@apply transition mt-8
		&.disble-btn
			@apply opacity-0 pointer-events-none
	.filter-table-wrap
		@apply border-t-2 border-dark mt-4
		@screen lg
			@apply mt-5
		@media screen and ( max-width: 768px)
			@apply overflow-x-auto
			table
				@apply whitespace-nowrap
		thead
			th
				@apply text-grey-333 font-bold  border-b border-grey-cc 
				padding: 13px 20px
				&:first-child
					@apply pl-0
		tbody
			tr
				@apply hidden
				td
					@apply border-b border-grey-cc text-grey-333 font-normal 
					padding: 13px 20px
					&:first-child
						@apply pl-0
					*
						@apply text-grey-333 font-normal
					a
						&:hover
							@apply underline
					em
						font-size: 24px
						@apply leading-none font-light
						@media screen and ( max-width: 1024px)
							font-size: 20px
						@media screen and ( max-width: 768px)
							font-size: 16px
.careers-benefit 
	@apply relative
	.block-wrap
		@apply relative
		@media screen and ( max-width: 1024px)
			@apply col-ver
	.container-fluid
		@apply p-0 bg-blue-E7F1F8 
		@media screen and ( max-width: 1024px)
			@apply order-2
		@screen lg
			@apply absolute-center z-30
	.txt-wrap
		padding: r(40px) 0 0 0
		@screen lg
			padding: r(70px) 0
	.container
		@apply relative z-30
.careers-contact-information 
	.headline
		@apply mb-4
	.desc
		@apply mb-1
		*
			@apply mb-2
		a
			@apply text-blue-006BEA