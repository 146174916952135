.global-breadcrumb
	@apply  flex justify-start items-center py-2
	min-height: 50px
	box-shadow: 5px 5px 25px 0px #0000000F
	ol
		@apply p-0 list-none
		li
			@apply inline-flex relative leading-none
			padding:  0 14px
			&:after
				content: '\f054'
				font-family: 'Font Awesome 6 Pro' 
				@apply text-grey-666 block font-light absolute-y pointer-events-none z-10 left-full
				font-size: 16px
				height: 16px
				width: 12px
				@media screen and ( max-width: 576px)
					font-size: 14px
			&:first-child
				@apply pl-0
				span,a
					font-size: 0 !important
				&:before
					content: '\f015'
					font-family: 'Font Awesome 6 Pro'
					@apply text-grey-666 block pointer-events-none z-10 font-light
					font-size: 16px
					height: 16px
					width: 16px
					@media screen and ( max-width: 576px)
						font-size: 14px
						height: 14px
						width: 14px
			&:last-child
				&:after
					@apply hidden
				a
					@apply text-grey-666
			a,span
				font-size: 16px
				@apply text-grey-666 font-normal
				@media screen and ( max-width: 576px)
					font-size: 14px