.product-zone-page
	.module-title
		@apply hidden
.product-zone
	.row
		@media screen and ( max-width: 1024px)
			@apply -mx-2 mb-2
			.col
				@apply px-2 mb-4
	&.home-product-zone
		.item-zone
			.wrap-left
				margin-top: r(30px)
				@screen lg
					margin-top: r(40px)
			.desc
				height: r(170px)
				@media screen and ( max-width: 1024px)
					@apply h-auto
.item-zone
	padding: 15px
	@apply bg-grey-f5 h-full
	@screen lg
		padding: 25px
		@apply  flex
	.img-src
		@apply w-full
		@screen lg
			width: 50%
		a
			+img-cover
	.txt
		padding-top: r(20px)
		@screen lg
			width: 50%
			padding:  r(30px) r(40px) 0  r(40px)
	.headline
		margin-bottom: r(14px)
		&:hover
			@apply underline
	.desc
		height: r(220px)
		padding-right: 15px
		overflow-y: auto
		scrollbar-width: thin
		scrollbar-color: #A0C4F4 #cccccc
		&::-webkit-scrollbar
			background: #cccccc
			width: 3px
		&::-webkit-scrollbar-track
			-webkit-box-shadow: inset 0 0 6px #cccccc
			background-color: #cccccc
		&::-webkit-scrollbar-thumb
			background-color: #A0C4F4
		@media screen and ( max-width: 1024px)
			@apply h-auto
		ul
			@apply col-hor
			a
				@apply text-grey-333 font-normal transition
				font-size: r(16px)
				&:hover
					@apply text-blue-006BEA
		ol
			li
				@apply text-grey-333 font-normal mb-1 transition
				font-size: r(16px)
				*
					@apply text-grey-333 font-normal
					font-size: r(16px)
				a
					@apply transition
					&:hover
						@apply text-blue-006BEA
				&::before
					content: '-'
					@apply inline-block  pointer-events-none z-10  transition text-grey-333
					font-size: r(16px)
					height: 2px
					width: 10px
				&:hover
					@apply text-blue-006BEA
					&::before
						@apply text-blue-006BEA
				