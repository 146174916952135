.top-banner
	.img-wrap
		@apply relative
		@media screen and ( max-width: 1024px)
			+img-ratio(9/16)
		@screen lg
			+img-ratio(424/1920)
	.txt-wrap
		@media screen and ( max-width: 576px)
			@apply py-6  text-center justify-center
		@screen sm
			@apply absolute-x z-30
			bottom: r(40px)
		@screen lg
			bottom: r(64px)
		.title
			@apply font-bold font-sharp leading-none text-grey-333
			font-size: r(46px)
			@screen sm
				@apply text-white 
			*
				font-size: r(46px)
			@screen lg
				font-size: r(64px)
				*
					font-size: r(64px)
			span
				@apply text-blue-006BEA