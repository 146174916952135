.row 
	@apply flex flex-wrap justify-start
.col
	flex: 0 0 auto
	@apply flex flex-col
	padding: 0 15px
	.col
		margin-bottom: 0
.row
	margin: 0 -15px -30px -15px
	> .col
		margin-bottom: 30px
.col-match-height
	@apply flex w-full flex-col h-full
.container, .container-fluid
	width: 100%
	padding-right: 15px
	padding-left: 15px
	margin-right: auto
	margin-left: auto
