.home-highlight-news
	.top-row
		.headline
			padding-top: r(20px)
			margin-left: r(80px)
	.trinity-swiper
		padding-top: r(40px)
		@screen lg
			padding-top: r(60px)
	.sub-title
		margin-bottom: r(30px)