.language-wrap
	@media screen and ( max-width: 1024px)
		@apply mr-3
.language-list
	@apply center-item
	li
		@apply inline-block leading-none
		&:not(:last-child)
			@apply border-r border-grey-bf
		&.active
			a
				@apply text-blue-0A2ECC font-bold
		a
			@apply center-item text-grey-666 font-normal leading-none
			padding: 0 10px
			font-size: r(20px)
			@screen lg
				font-size: 14px