footer
	@apply bg-blue-E7F1F8
	padding: r(40px) 0
	@screen lg
		padding: r(40px)  0
	.container
		@screen lg
			padding: 0 r(100px)
	.top-row
		@screen lg
			margin: 0 -37px
			.col
				padding: 0 37px
				margin-bottom: 17px
				&:not(:last-child)
					border-right: 1px solid #d9d9d9
	.bottom-row
		@media screen and ( max-width: 1024px)
			@apply mb-0
			.col
				@apply mb-0
	.wrap-left
		@media screen and ( max-width: 1024px)
			@apply col-ver
	.open-time-wrap
		.headline
			@apply mb-1
		.hotline-title
			@apply mb-2
		.txt
			@apply text-blue-006BEA font-bold leading-tight mb-2
			*
				@apply text-blue-006BEA font-bold
	.headline
		@apply text-dark mb-5
		font-size: 16px
		*
			@apply text-dark
			font-size: 16px
		@media screen and ( max-width: 1600px)
			font-size: r(20px)
			*
				font-size: r(20px)
	ol
		li
			@apply text-dark font-normal mb-1
			font-size: clamp(14px, r(18px),18px)
			*
				font-size: clamp(14px, r(18px),18px)
			@media screen and ( min-width: 1600px)
				font-size: clamp(12px,r(14px),14px)
				*
					font-size: clamp(12px,r(14px),14px)
	.copyright
		@apply text-dark font-normal 
		font-size: r(18px)
		@screen lg
			@apply mb-4
		*
			font-size: r(18px)
		@media screen and ( min-width: 1600px)
			font-size: r(14px)
			*
				font-size: r(14px)
		@media screen and ( max-width: 1024px)
			@apply text-center my-6
	.footer-nav
		@apply end-item
		li
			@apply border-r border-r-dark leading-none
			&:first-child
				a
					@apply pl-0
			&:hover
				a
					@apply underline
			&.active
				a
					@apply text-blue-0A2ECC underline
				
		a
			@apply text-dark font-normal leading-none center-item
			
			font-size: r(18px)
			*
				font-size: r(18px)
			@media screen and ( min-width: 1600px)
				font-size: r(14px)
				*
					font-size: r(14px)
	.footer-menu
		@media screen and ( max-width: 768px)
			@apply flex-wrap
			li
				@apply mb-2
		a
			padding: 0 r(14px)
	.policy-nav-wrap
		@apply mt-auto
	.policy-nav
		@apply mt-auto
		@media screen and ( max-width: 1024px)
			@apply justify-center
		a
			padding: 0 r(6px)
		li
			&:last-child
				a
					@apply pr-0
			&:last-child
				border-right: 0
	.social-network 
		.ModuleContent
			@apply start-item
		@apply start-item 
		@screen lg
			@apply ml-[20px]
		@media screen and ( max-width: 1024px)
			@apply my-6
		a
			@apply center-item
			width: 32px
			height: 32px
			&:hover
				em
					@apply text-blue-0A2ECC
			em
				@apply text-grey-333 leading-none transition
				font-size: 16px
.side-tool-nav
	@apply fixed right-[20px] bottom-[50px] z-50
	ul
		@apply col-ver
	li
		&:not(:last-child)
			@apply mb-[10px]
	a
		@apply center-item bg-white rounded-full overflow-hidden 
		width: r(50px)
		height: r(50px)
		transition: .35s all  ease-in-out
		+boxshadow
		&:hover
			@apply bg-blue-0A2ECC
			em
				@apply text-white
		em
			font-size: r(24px)
			@apply leading-none text-blue-0A2ECC
			transition: .35s all  ease-in-out