.about-conduct-csr
	.desc
		@apply text-center
		font-size: r(28px)
		*
			font-size: r(28px)
		strong
			@apply font-bold text-blue-0A2ECC
		@screen lg
			font-size: r(36px)
			*
				font-size: r(36px)
	.top-nav-wrap
		@apply mb-3
		@screen md
			@apply mb-7
		@media screen and ( max-width: 768px)
			+res-nav
	.img-wrap
		a
			@apply center-item
	.headline
		@apply mt-4
	.block-wrap
		@apply border-b border-b-grey-cc
	.block-item-wrap
		.item
			@apply start-item
			align-items: center
			@media screen and ( max-width: 576px)
				@apply col-ver text-center
		.icon
			display: flex
			height: 50px
			width: auto
			min-width: 50px
			@screen sm
				margin-right: r(30px)
			@media screen and ( max-width: 576px)
				@apply mb-3
			img
				width: 100%
				height: 100%
				object-fit: contain
		.desc
			@apply text-grey-151 leading-normal text-left
			font-size: r(20px)
			*
				@apply text-grey-151 leading-normal
				font-size: r(20px)
		.row
			@apply justify-start
			@screen lg
				margin: 0 -40px -70px -40px
				.col
					padding: 0 40px
					margin-bottom: 70px
