.download-document
	.top-nav-wrap
		margin-bottom: r(40px)
		@media screen and ( max-width: 768px)
			+res-nav
	.table-wrap 
		table
			@media screen and ( max-width: 1024px)
				@apply whitespace-normal
			td,th
				&:first-child,&:last-child
					width: 16%
				&:last-child
					@apply text-center
			tbody
				td
					@apply bg-white relative
				.thead
					counter-increment: numb
					counter-reset: section
					th
						@apply text-blue-0A2ECC
				.tnormal
					td
						@apply border-b border-b-grey-f5
				th
					@apply relative  bg-grey-f5 border-r border-r-grey-cc
					padding: r(16px)  r(30px)
					font-size: r(16px)
					@media screen and ( max-width: 576px)
						padding: r(12px)  r(20px)
						font-size: r(18px)
						*
							font-size: r(18px)
					&:first-child
						@apply  text-blue-0A2ECC font-bold leading-none
						font-size: 16px
			