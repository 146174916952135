.product-detail 
	.block-title
		margin-bottom: r(40px)
		@screen lg
			margin-bottom: r(70px)
	.zone-title
		margin-bottom: 30px !important
	.table-wrapper
		@media screen and ( max-width: 1024px)
			@apply overflow-x-auto
		tbody
			tr
				&:nth-child(odd)
					td
						@apply bg-grey-f5
					
				td
					font-size: r(16px)
					@apply text-grey-151 font-normal bg-white
					padding: r(20px) 0 r(20px) r(20px)
					@screen lg
						padding: r(35px)  0  r(35px) r(35px)
					&:first-child
						font-size: r(18px)
						@apply text-blue-0A2ECC font-bold
						@screen lg
							width: 32%
					p
						@apply mb-3
					h2
						@apply font-bold my-3
						font-size: 24px
						*
							@apply font-bold
							font-size: 24px
					h3
						@apply font-bold my-2
						font-size: 20px
						*
							@apply font-bold
							font-size: 20px
					h4
						@apply font-bold my-2 pl-6
						font-size: 16px
						*
							@apply font-bold
							font-size: 16px
					strong
						@apply font-bold
					a
						@apply text-blue-0A2ECC
					ul
						@apply mb-2
						li
							@apply start-item
							&:hover
								@apply text-blue-0A2ECC
								*
									@apply text-blue-0A2ECC
							&::before
								content: ''
								@apply inline-block z-10  transition  bg-blue-0A2ECC rounded-full mr-1 mt-2
								width: 6px
								height: 6px
								min-width: 6px
								@screen lg
									@apply mr-2
		.table-wrap
			thead
				th
					&:nth-child(n+3)
						@apply text-center
						em
							@apply text-blue-0A2ECC
			tbody
				tr
					&:nth-child(even)
						td
							background: $grey-f5 !important
					td
						background: white
						padding: r(12px) r(20px)  !important
						@screen lg
							padding: r(12px) r(30px)  !important

						&:first-child
							@apply text-grey-151 text-center
							@screen lg
								width: 10% !important
						
						&:nth-child(n+3)
							@apply text-center
							em
								@apply text-blue-0A2ECC