.pop-up-modal
	padding: 0
	border-radius: 15px
	@apply w-full
	max-width: 1162px
	.frm-btnwrap
		@screen lg
			@apply  absolute right-0 bottom-0
.fancybox__slide
	@screen lg
		padding: 0 !important
.fancybox__container
	padding: 30px
.carousel__button.is-close
	width: 52px
	height: 52px
	top:  0 !important
	border-top-right-radius: 15px
	@apply bg-blue-0A2ECC rounded-none overflow-hidden
	@media screen and ( max-width: 1024px)
		width: 40px
		height: 40px
	svg
		color: white
		font-size: 32px
		height: 32px
		width: 20px
.recruitment-detail-modal
	padding: r(30px) r(40px) !important
	@screen lg
		padding: r(55px)  r(85px) !important
	.block-title
		font-size: r(40px)
	.wrap-form
		.form-group
			margin-bottom: 25px !important
			textarea
				@screen lg
					height: 160px
.inquiries-modal,.whisteblowing-modal
	padding: r(30px) r(40px) !important
	@screen lg
		padding: r(40px)  r(110px) !important
	.block-title
		margin-bottom: r(30px)
.inquiries-modal
.whisteblowing-modal
	.check-stat
		ul
			@screen sm
				@apply start-item flex-wrap flex-row
				justify-content: flex-start
			li
				width: 25%
				@media screen and ( max-width: 768px)
					@apply w-1/2
				@media screen and ( max-width: 576px)
					@apply w-full