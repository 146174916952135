.home-claim
	@apply bg-blue-E7F1F8
	.row
		@apply justify-between
	.list
		margin-top: r(40px)
		@screen lg
			margin-top: r(60px)
		ul
			@apply col-hor
		li
			@apply w-full
			&:first-child
				a
					@apply border-t-2  border-grey-111
			&:last-child
				a
					@apply border-b border-grey-cc
			&:hover
				a
					@apply border-t-blue-0A2ECC
					em
						@apply opacity-100
			a
				@apply text-grey-333 font-normal gap-item border-t border-t-grey-cc 
				transition: .4s all  ease-in-out
				font-size: r(24px)
				padding: r(12px) 0
				@screen lg
					padding: r(20px) 0
					font-size: r(32px)
				*
					@apply text-grey-333 font-normal 
					font-size: r(24px)
				em
					font-size: 24px
					transition: .4s all  ease-in-out
					@apply leading-none text-grey-333 opacity-0 pointer-events-none 

	.img-wrap 
		a
			+img-cover