.btn
	span,em
		transition: .4s all  ease-in-out
	&.btn-primary
		@apply overflow-hidden bg-transparent border border-grey-333 px-8 rounded-full
		height: r(46px)
		min-width: r(180px)
		span
			@apply text-grey-333 uppercase font-normal relative z-50 leading-none
			transition: .35s all  ease-in-out
		&:hover
			@apply border-blue-006BEA bg-blue-006BEA
			span
				@apply text-white
		&.blue
			@apply border-blue-0A2ECC
			span
				@apply text-blue-0A2ECC
			&:hover
				span
					@apply text-white
	&.btn-view-more
		span
			@apply text-blue-006BEA font-normal  leading-none
			font-size: 16px
		em
			@apply text-blue-006BEA inline-block ml-4
.nav-prev,.nav-next
	@apply z-10 transition  absolute top-1/2 translate-y-50    cursor-pointer center-item  rounded-full  
	border: 2px solid #ccc
	width: 50px
	height: 50px
	line-height: 50px
	transition: .4s all  ease-in-out
	@media screen and ( max-width: 1440px)
		width: 42px
		height: 42px
		line-height: 42px 
	@media screen and ( max-width:  1024px)
		@apply static mx-6
		transform: none
		width: 40px
		height: 40px
		line-height: 40px
	em
		@apply  inline-block transition text-grey-cc
		font-size: 32px
		height: 32px
		line-height: 32px
		transition: .4s all  ease-in-out
		@media screen and ( max-width: 1440px)
			font-size: 24px
			height: 24px
			line-height: 24px
	&:hover
		@apply border-blue-0A2ECC 
		em
			@apply text-blue-0A2ECC
.swiper-nav-wrap
	@media screen and ( max-width: 1024px)
		@apply center-item pt-8
	&.white
		background: none
		.nav-next,.nav-prev
			em
				@apply text-white
			&:hover
				em
					@apply text-white
.nav-next
	right: -70px
	@media screen and ( min-width: 1024px) and ( max-width: 1440px)
		right: -50px
.nav-prev
	left:  -70px
	@media screen  and ( min-width: 1024px) and ( max-width: 1440px)
		left: -50px
.wrap-gap,.wrap-center,.wrap-left,.wrap-right,.ajax-center-wrap
	@apply flex items-center
	a
		&:not(:last-child)
			@apply mr-2
.wrap-center,.ajax-center-wrap
	@apply  justify-center
.wrap-left
	@apply justify-start
.wrap-right
	@apply justify-end
.wrap-gap
	@apply justify-between
.ajax-center-wrap
	@apply mt-8
	&.disble-btn
		@apply opacity-0 pointer-events-none