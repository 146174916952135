.news-display-page
	.modulepager
		padding-top: 0
		padding-bottom: r(60px)
.notice-announcement
	padding-bottom: r(60px)
	.row
		@apply justify-start
	.block-title
		margin-bottom: r(40px)
.news-item
	.img-src
		a
			+img-ratio(270/480)
	.txt
		margin-top: r(20px)
	.headline
		a
			@apply transition
			+clamp(20px,1.3,2)
			&:hover
				@apply text-blue-0A2ECC
	.wrap-left
		@apply mt-3