.wrap-form
	@apply relative
	.row
		box-shadow: none !important
		margin: 0 -15px  !important
		.col
			margin-bottom: 20px
	.fa-exclamation-triangle
		@apply absolute font-normal 
		bottom: -22px
		left: 20px
		color: #f30000
		font-size: 12px
	.form-group
		@apply relative 
		margin-bottom: 20px !important
		input[type='text'],textarea,select
			height: 46px
			background: #ffffff
			border: 1px solid #CCCCCC
			border-radius: 10px
			@apply text-grey-333 px-5 
			font-size: 16px
			@media screen and ( max-width:1024px )
				height: 40px
			&:focus
				outline: none
			&::placeholder
				@apply text-grey-333 font-normal
				font-size: 16px
				@media screen and ( max-width: 1024px)
					@apply text-14
		select
			option
				@apply text-grey-333
				font-size: 16px
		textarea
			padding: 16px 20px
			height: 7rem
			@screen lg
				height: 90px
	.frm-btnwrap
		.frm-btn
			input[type='submit']
				width: 180px
				height: 46px
				border-radius: 25px
				@apply text-white  text-16 bg-blue-0A2ECC transition font-medium border border-blue-0A2ECC uppercase
				@media screen and ( max-width: 1024px) 
					height: 40px
				&:hover
					@apply bg-white text-blue-0A2ECC
.policy-check
	.title
		@apply text-grey-333 font-bold block mb-2
		padding: 0 15px
		font-size: 16px
		&::after
			content: '*'
			@apply inline-block absolute pointer-events-none  z-10  transition text-primary
	.chk-group
		input[type='checkbox']
			@apply  absolute top-0 opacity-0 left-0 right-0  z-30 w-full h-full
			&:checked ~ label
			&:checked ~ label:before
				content: '\f14a'
		label
			@apply  pointer-events-none  flex items-start text-grey-333 leading-normal
			font-size: 16px
			&:before
				font-family: 'Font Awesome 6 Pro'
				content: '\f0c8'
				width: 30px
				height: 30px
				display: inline-block
				line-height: 30px
				font-size: 30px
				color: #CCCCCC
				transition: .3s all  ease-in-out
				margin-right: 20px
				padding-top: 4px
				@media screen and ( max-width: 1024px)
					width: 24px
					height: 24px
					@apply mr-4
.input-radio
	>label
		@apply text-grey-333 font-bold block mb-3
		font-size: 16px
	ul
		@apply col-hor
		li
			@apply relative mb-2
		input[type='radio']
			@apply  absolute top-0 opacity-0 left-0 right-0  z-30 w-full h-full
			&:checked ~ label
			&:checked ~ label:before
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='6' fill='%230A2ECC'/><circle cx='10' cy='10' r='9.5' stroke='%230A2ECC'/></svg>")
		label
			@apply  pointer-events-none  flex items-start text-grey-333 leading-normal
			font-size: 16px
			&:before
				content: ''
				background-repeat: no-repeat
				background-position: center !important
				appearance: none !important
				outline: 0px !important
				outline-offset: 0px !important
				box-shadow: none !important
				color: transparent
				width: 20px
				height: 20px
				min-width: 20px
				display: inline-block
				line-height: 20px
				font-size: 30px
				margin: 4px 20px 0 0
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='9.5' stroke='%230A2ECC'/></svg>")
			
.frm-btn-reset
	display: none
.frm-captcha
	margin:   0 !important
	display: inline-flex
	flex-direction: row-reverse
	img
		height: 44px !important
		max-width: auto
		max-width: 150px
		@media screen and ( max-width:1200px)
			max-width: 150px
			
	@media screen and (max-width:576px)
		flex-direction: column-reverse
	.frm-captcha-input
		@apply mt-0
		margin-left: 50px
		@media screen and ( max-width:576px)
			margin-left: 0
			@apply mt-4
		label
			display: none
		input
			padding: 0 10px
			margin-top: 0
			height: 30px
			@media screen and ( max-width:1280px)
				max-width: 200px
			@media screen and ( max-width:1200px)
				max-width: 150px
				
			@media screen and ( max-width:1024px)
				max-width: 200px
				
			@media (max-width: 767.98px)
				margin-left: 20px
			@media (max-width: 576.98px)
				margin-left: 0
	.rcRefreshImage
		position: absolute
		opacity: 1
		cursor: pointer
		height: 0
		width: 0
		right: -5px
		top: 5px
		color: #ffffff
		z-index: 0
		font-size: 0
		&:before
			font-family: 'Material Design Icons'
			position: absolute
			top: 0
			right: -40px
			opacity: 1
			z-index: 99
			color: #9d9080
			font-size: 30px
			content: "\F0450"
			display: flex
			justify-content: center
			align-items: flex-start
			@media screen and ( max-width:576px)
				height: 50px
@media screen and ( max-width:500px )
	#ctl00_mainContent_ctl05_captcha_ctl00
		display: none
#ctl00_mainContent_ctl02_captcha_ctl01
	position: relative
#ctl00_mainContent_ctl06_btnReset,#ctl00_mainContent_ctl05_captcha_ctl00,#ctl00_mainContent_ctl08_captcha_ctl00,#ctl00_mainContent_ctl07_captcha_ctl00
	display: none
#ctl00_mainContent_ctl06_txtCaptcha
	margin-left: 10px
#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton
	#text
		display: none
.RadCaptcha
	@apply relative
	@media screen and ( max-width:767.5px)
		margin-left: 0
	span
		@apply hidden
	#ctl00_mainContent_ctl04_captcha_ctl00
		display: block !important
		position: absolute
		left: 0
		top: 100%
		@apply text-primary text-12
	>div
		>div
			display: flex
			position: relative
			flex-direction: column-reverse
			height: 40px !important
			margin-top: 0
			@media (max-width: 767.98px)
				margin-right: 0
				width: 180px