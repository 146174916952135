.timeline-social-wrap 
	@apply center-item relative  border-b border-b-grey-cc border-t-2 border-t-grey-333
	padding: r(15px) 0
	time
		@apply text-grey-666 font-normal
	.fa-share
		@apply absolute-y right-0 z-10
		em
			font-size: 24px
			color: #1877F2
.career-detail 
	.title-wrap
		@apply gap-item
		@media screen and ( max-width: 576px)
			@apply col-ver pb-6
	.block-title
		@apply mb-3 text-left
		@media screen and ( max-width: 576px)
			@apply mb-5
	.table-detail-wrap 
		@screen lg
			max-width: 50%
		tr
			@media screen and ( max-width: 576px)
				@apply col-ver
		td
			@apply w-full
			font-size: 16px 
			padding: 5px 0
			@apply leading-normal
			@screen lg
				@apply w-1/2
			*
				font-size: 16px 
				@apply leading-normal
			time
				font-size: 16px
				@apply text-blue-0A2ECC
			em
				@apply leading-none inline-block mr-4
				@apply mr-6
			

.other-recruitment 
	padding-bottom: r(60px)
	.block-title
		@apply text-left
	time
		@apply text-grey-666 mt-2
		font-size: r(16px)
.form-apply
	iframe
		width: 100%
		height: 410px
		@screen xl
			@apply w-full
#ctl00_phMain_jobApply_upContact
	margin-bottom: 0 !important
.apply-frm
	margin: 0 !important
	overflow: hidden
	background: #fff !important
	width: 100%
	height: 100%
	@media screen and ( max-width:  576px)
		overflow: hidden
		width: 100%
	.recruitment-container
		min-width: 1110px !important
	.frm-captcha
		@apply my-6
		@screen lg
			@apply absolute right-0  pr-8
			bottom: 40px
			width: auto !important
			margin-top: 0 !important
	.wrap-form
		@apply relative
		padding: r(30px)
		@screen lg
			height: auto
			padding: r(30px)
	h4
		@apply hidden
	input[type="file"]
		left: 0
	>div
		width: 100%
	.RadUpload 
		width: 320px
		pointer-events:  auto
		.ruButton
			@apply text-16 uppercase opacity-0
			font-family: 'Nunito Sans', sans-serif
		.ruInputs
			width: 320px
			position: relative
		.ruRemove
			position: absolute
			bottom: -44px
			left: 360px
			opacity: 1
			font-size: 12px
		.ruBrowse
			@apply hidden
	#ctl00_phMain_jobApply_pnlNewComment
		position: relative
		display: flex
		flex-wrap: wrap
		>.form-group
			width: 100%
			margin-top: -10px
			position: relative
		.col-left,.col-right
			flex: 0 0 100%
			max-width: 100%
			display: flex
			flex-wrap: wrap
			>.form-group
				margin-bottom: 30px
			@media (min-width: 576px)
				margin: 0 -10px
				>.form-group
					padding: 0 10px
			
	.col-left
		>.form-group
			flex: 0 0 100%
			max-width: 100%
			@media (min-width: 576px)
				&:nth-child(1),
				&:nth-child(2),
				&:nth-child(3),
				&:nth-child(4)
					flex: 0 0 50%
					max-width: 50%
	.col-right
		>.form-group
			flex: 0 0 100%
			max-width: 100%
	.fa-exclamation-triangle
		@apply absolute font-normal 
		bottom: -22px
		left: 20px
		color: #f30000
		font-size: 12px
	.label
		display: none
		font-size: 14px
		font-weight: 400
		color: #999999
		margin-bottom: 4px
		.required
			color: red
	
	textarea
		height: 90px
		@screen xl
			height: 130px
	.RadAsyncUpload
		.ruDropZone
			display: none
		.ruFileWrap
			position: static
			display: block
			height: auto !important
			max-height: 50px
			.ruUploadProgress 
				position: absolute
				bottom: -18px
				left: 360px
		.ruFileInput, .rugreyse
			height: 50px
			width: 120px
			height: 50px
			text-transform: uppercase
			font-size: 15px
			display: flex
			justify-content: center
			align-items: center
			border: 1px solid $primary
			background: $primary
			height: 50px
			width: 250px
			&:hover
				background: white !important
				color: $primary !important
		.ruFileInput
			cursor: pointer
			border: 1px solid $primary
			pointer-events: auto
			top: 0 !important
			left: 0
			padding: 0 !important
			bottom: 10px
			position: absolute
			opacity: 0
			height: 40px
			width: 320px !important
			cursor: pointer
			&:hover
				background: white !important
				color: $primary !important
	.form-group-btn
		@media screen and ( max-width: 1024px)
			@apply start-item mt-6
		@screen lg
			@apply absolute right-[20px] bottom-0
	.frm-btnwrap
		@apply start-item w-auto mt-0
		@media screen and ( max-width: 576px)
			position: static !important
	.attachfile1
		@apply block relative 
		.left
			@apply absolute top-0 left-0 bottom-0 right-0 w-full h-full  cursor-pointer pointer-events-none
			z-index: 99
			max-width: 180px
		label
			@apply center-item text-16  font-normal text-grey-333 uppercase relative pointer-events-none text-white bg-grey-f5
			margin-top: 0
			background: #F5F5F5
			width: 320px
			height: 40px
			color: #333 !important
			font-weight: 400 !important
			@media screen and ( max-width: 1024px)
				@apply w-full px-3
				word-break: break-normal 
				font-size: r(16px)
				*
					font-size: r(16px)
			*
				color: #333 !important
				font-weight: 400 !important
			input[type='file']
				@apply w-full cursor-pointer
			input[type='file']::file-selector-button
				cursor: pointer
			input[type='file']::-webkit-file-upload-button 
				cursor: pointer
			::-webkit-file-upload-button 
				cursor: pointer
	.attachfile2
		display: none
	.form-group 
		position: relative
		&.disable-input
			input
				@apply pointer-events-none
				background: #F1F1F1
				border: none