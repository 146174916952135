html {
  -webkit-text-size-adjust: 100%;
  box-sizing: border-box;
  font-family: sans-serif;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  line-height: 1.2;
  scroll-behavior: smooth; }

body {
  margin: 0 10px; }

main {
  display: block; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  border-top-width: 1px; }

pre {
  font-family: monospace, monospace;
  font-size: 1em; }

a {
  background-color: transparent;
  color: inherit;
  text-decoration: inherit; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none;
  border-style: solid;
  max-width: 100%;
  height: auto; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.2;
  margin: 0;
  padding: 0;
  line-height: inherit;
  color: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em;
  margin: 0;
  padding: 0; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto;
  resize: vertical; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

*,
*::before,
*::after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e2e8f0; }

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0; }

button {
  background: transparent;
  padding: 0; }
  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #a0aec0; }

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  color: #a0aec0; }

input::placeholder,
textarea::placeholder {
  color: #a0aec0; }

button,
[role="button"] {
  cursor: pointer; }

table {
  border-collapse: collapse;
  width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit; }

pre,
code,
kbd,
samp {
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle; }

img,
video {
  max-width: 100%;
  height: auto; }

section {
  display: block; }

footer {
  @apply flex flex-col {} }

html, body {
  color: #333333;
  font-weight: 400;
  font-size: 3.6vw;
  font-family: 'SamsungOne'; }

@screen sm {
  html, body {
    font-size: 2.4vw; } }

@screen md {
  html, body {
    font-size: 1.5vw; } }

@screen lg {
  html, body {
    font-size: 1.1vw; } }

@screen xl {
  html, body {
    font-size: 1vw; } }

*:focus {
  outline: none; }

a {
  color: #333333;
  text-decoration: none; }
  a:hover {
    color: #333333; }

p {
  margin-bottom: 10px;
  font-weight: 400;
  color: #333333;
  font-size: 1rem; }

figure p {
  font-weight: 300;
  font-size: 1rem; }

time {
  @apply font-normal  inline-block {}  color: #999999; }

html {
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
  color: #333333;
  text-align: left;
  background-color: #ffffff; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

.frm-btn-reset {
  display: none !important; }

.col-12 {
  @apply w-full {}  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 0 15px; }

.col-lg-8 {
  padding: 0 15px; }

@screen lg {
  .col-lg-8 {
    width: 66.666667%; } }

.col-lg-4 {
  padding: 0 15px; }

@screen lg {
  .col-lg-4 {
    width: 33.333333%; } }

.col-xs-12 {
  padding: 0 15px;
  width: 100%; }

.col-lg-3 {
  padding: 0 15px; }

@screen lg {
  .col-lg-3 {
    width: 25%; } }

.col-lg-9 {
  padding: 0 15px; }

@screen lg {
  .col-lg-9 {
    width: 75%; } }

.btn span, .btn em {
  transition: .4s all  ease-in-out; }

.btn.btn-primary {
  @apply overflow-hidden bg-transparent border border-grey-333 px-8 rounded-full {}  height: 2.39583rem;
  min-width: 9.375rem; }
  .btn.btn-primary span {
    @apply text-grey-333 uppercase font-normal relative z-50 leading-none {}    transition: .35s all  ease-in-out; }
  .btn.btn-primary:hover {
    @apply border-blue-006BEA bg-blue-006BEA {} }
    .btn.btn-primary:hover span {
      @apply text-white {} }
  .btn.btn-primary.blue {
    @apply border-blue-0A2ECC {} }
    .btn.btn-primary.blue span {
      @apply text-blue-0A2ECC {} }
    .btn.btn-primary.blue:hover span {
      @apply text-white {} }

.btn.btn-view-more span {
  @apply text-blue-006BEA font-normal  leading-none {}  font-size: 16px; }

.btn.btn-view-more em {
  @apply text-blue-006BEA inline-block ml-4 {} }

.nav-prev, .nav-next {
  @apply z-10 transition  absolute top-1/2 translate-y-50    cursor-pointer center-item  rounded-full {}  border: 2px solid #ccc;
  width: 50px;
  height: 50px;
  line-height: 50px;
  transition: .4s all  ease-in-out; }
  @media screen and (max-width: 1440px) {
    .nav-prev, .nav-next {
      width: 42px;
      height: 42px;
      line-height: 42px; } }
  @media screen and (max-width: 1024px) {
    .nav-prev, .nav-next {
      @apply static mx-6 {}      transform: none;
      width: 40px;
      height: 40px;
      line-height: 40px; } }
  .nav-prev em, .nav-next em {
    @apply inline-block transition text-grey-cc {}    font-size: 32px;
    height: 32px;
    line-height: 32px;
    transition: .4s all  ease-in-out; }
    @media screen and (max-width: 1440px) {
      .nav-prev em, .nav-next em {
        font-size: 24px;
        height: 24px;
        line-height: 24px; } }
  .nav-prev:hover, .nav-next:hover {
    @apply border-blue-0A2ECC {} }
    .nav-prev:hover em, .nav-next:hover em {
      @apply text-blue-0A2ECC {} }

@media screen and (max-width: 1024px) {
  .swiper-nav-wrap {
    @apply center-item pt-8 {} } }

.swiper-nav-wrap.white {
  background: none; }
  .swiper-nav-wrap.white .nav-next em, .swiper-nav-wrap.white .nav-prev em {
    @apply text-white {} }
  .swiper-nav-wrap.white .nav-next:hover em, .swiper-nav-wrap.white .nav-prev:hover em {
    @apply text-white {} }

.nav-next {
  right: -70px; }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .nav-next {
      right: -50px; } }

.nav-prev {
  left: -70px; }
  @media screen and (min-width: 1024px) and (max-width: 1440px) {
    .nav-prev {
      left: -50px; } }

.wrap-gap, .wrap-center, .wrap-left, .wrap-right, .ajax-center-wrap {
  @apply flex items-center {} }
  .wrap-gap a:not(:last-child), .wrap-center a:not(:last-child), .wrap-left a:not(:last-child), .wrap-right a:not(:last-child), .ajax-center-wrap a:not(:last-child) {
    @apply mr-2 {} }

.wrap-center, .ajax-center-wrap {
  @apply justify-center {} }

.wrap-left {
  @apply justify-start {} }

.wrap-right {
  @apply justify-end {} }

.wrap-gap {
  @apply justify-between {} }

.ajax-center-wrap {
  @apply mt-8 {} }
  .ajax-center-wrap.disble-btn {
    @apply opacity-0 pointer-events-none {} }

.row {
  @apply flex flex-wrap justify-start {} }

.col {
  flex: 0 0 auto;
  @apply flex flex-col {}  padding: 0 15px; }
  .col .col {
    margin-bottom: 0; }

.row {
  margin: 0 -15px -30px -15px; }
  .row > .col {
    margin-bottom: 30px; }

.col-match-height {
  @apply flex w-full flex-col h-full {} }

.container, .container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.block-title {
  @apply text-dark font-bold  leading-tight text-lg  text-center font-sharp {}  margin-bottom: 20px; }
  .block-title * {
    @apply text-dark font-bold  text-lg font-sharp {} }

@screen lg {
  .block-title {
    @apply text-xl {} }
    .block-title * {
      @apply text-xl {} } }

.eyebrow {
  @apply text-dark font-bold  relative  leading-none {}  font-size: 1.875rem; }
  .eyebrow * {
    @apply text-dark font-bold {}    font-size: 1.875rem; }

@screen lg {
  .eyebrow {
    font-size: 2.86458rem; }
    .eyebrow * {
      font-size: 2.86458rem; } }

.headline {
  @apply text-grey-333 font-bold  leading-tight text-xs {} }
  .headline * {
    @apply text-grey-333 font-bold text-xs {} }
  .headline.t-16 {
    font-size: 0.83333rem; }
    .headline.t-16 * {
      font-size: 0.83333rem; }
  .headline.t-18 {
    font-size: 0.9375rem; }
    .headline.t-18 * {
      font-size: 0.9375rem; }
  .headline.t-20 {
    font-size: 1.04167rem; }
    .headline.t-20 * {
      font-size: 1.04167rem; }
  .headline.t-32 {
    @apply text-sm {} }
    .headline.t-32 * {
      @apply text-sm {} }
  .headline.grey {
    @apply text-grey-333 {} }
    .headline.grey * {
      @apply text-grey-333 {} }
  .headline.white {
    @apply text-white {} }
    .headline.white * {
      @apply text-white {} }
  .headline.blue {
    @apply text-blue-0A2ECC {} }
    .headline.blue * {
      @apply text-blue-0A2ECC {} }
  .headline.normal {
    @apply font-normal {} }
    .headline.normal * {
      @apply font-normal {} }

.sub-line {
  @apply text-grey-333 font-normal leading-tight start-item {}  font-size: 0.83333rem;
  align-items: center; }
  .sub-line * {
    @apply text-grey-333 font-normal {}    font-size: 0.83333rem; }
  .sub-line::before {
    content: '';
    @apply block  pointer-events-none  z-10  transition bg-blue-006BEA {}    height: 3px;
    width: 3.125rem;
    margin-right: 1.04167rem;
    min-width: 3.125rem; }
  .sub-line.white {
    @apply text-white {} }
    .sub-line.white * {
      @apply text-white {} }

.desc-light {
  @apply text-grey-333 font-extralight leading-tight text-center text-xs {} }
  .desc-light * {
    @apply text-grey-333 font-extralight  text-center text-xs {} }

.desc {
  @apply text-grey-333 font-normal leading-normal {}  font-size: 1.04167rem; }
  .desc strong {
    @apply font-bold text-blue-0A2ECC {} }
  .desc * {
    @apply text-grey-333 font-normal {}    font-size: 1.04167rem; }
  .desc p {
    @apply mb-4 {} }

@screen lg {
  .desc p {
    @apply mb-6 {} } }
    .desc p:last-child {
      @apply mb-0 {} }
  .desc.t-16 {
    font-size: 0.83333rem; }
    .desc.t-16 * {
      font-size: 0.83333rem; }
  .desc.t-18 {
    font-size: 0.9375rem; }
    .desc.t-18 * {
      font-size: 0.9375rem; }
  .desc.t-24 {
    font-size: 1.25rem; }
    .desc.t-24 * {
      font-size: 1.25rem; }
  .desc.t-36 {
    @apply text-md {} }
    .desc.t-36 * {
      @apply text-md {} }
  .desc.white {
    @apply text-white {} }
    .desc.white * {
      @apply text-white {} }

@tailwind base {}@tailwind components {}@tailwind utilities {}@-webkit-keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fade-in {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.gradient-blue {
  background-image: -webkit-linear-gradient(90deg, #47b5e8 0%, #2b74ce 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase; }

.ripple {
  margin: auto;
  margin-top: 5rem;
  background-color: #666;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: relative;
  animation: ripple 3s linear infinite; }
  .ripple::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: inherit;
    animation-delay: 1.5s; }
  .ripple::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    animation: inherit;
    animation-delay: 1.5s;
    animation-delay: 3s; }

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.8); }
  100% {
    box-shadow: 0 0 0 1rem rgba(255, 255, 255, 0); } }

.shine {
  overflow: hidden;
  position: relative; }
  .shine:before {
    position: absolute;
    top: 0;
    pointer-events: none;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg); }
  .shine:hover:before {
    -webkit-animation: shine .75s;
    animation: shine .75s; }

@-webkit-keyframes shine {
  100% {
    left: 125%; } }

@keyframes shine {
  100% {
    left: 125%; } }

.circle {
  overflow: hidden;
  position: relative; }
  .circle:before {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    display: block;
    content: '';
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 100%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 0; }
  .circle:hover:before {
    animation: circle .75s; }

@-webkit-keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

@keyframes circle {
  0% {
    opacity: 1; }
  40% {
    opacity: 1; }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0; } }

.zoom-in {
  overflow: hidden;
  transition: .45s all ease-in-out; }
  .zoom-in:hover img {
    transform: scale(1.05); }
  .zoom-in img {
    transform: scale(1);
    transition: .3s all ease-in-out; }

.opacity {
  transition: .3s all ease-in-out; }
  .opacity:hover {
    opacity: .8; }
  .opacity img {
    opacity: 1;
    transition: .3s all ease-in-out; }

@keyframes flash {
  0% {
    opacity: .4; }
  100% {
    opacity: 1; } }

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

@-moz-keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

@keyframes loadingFade {
  0% {
    opacity: 0; }
  50% {
    opacity: 0.8; }
  100% {
    opacity: 0; } }

.hamburger--elastic .hamburger-inner {
  top: -1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner:before {
    top: 10px;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner:after {
    top: 20px;
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 10px, 0) rotate(135deg);
  transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner:before {
    transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner:after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: 0.075s; }

.hamburger {
  padding: 0 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }
  .hamburger.is-active:hover {
    opacity: 1; }
  .hamburger.is-active .hamburger-inner,
  .hamburger.is-active .hamburger-inner:before,
  .hamburger.is-active .hamburger-inner:after {
    background-color: #0A2ECC; }

.hamburger-box {
  width: 32px;
  height: 23px;
  display: block;
  margin: auto 0;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px; }
  .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
    width: 32px;
    height: 3px;
    background-color: #0A2ECC;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner:before, .hamburger-inner:after {
    content: "";
    display: block; }
  .hamburger-inner:before {
    top: -10px; }
  .hamburger-inner:after {
    bottom: -10px; }

.fa-light, .fa-regular, .fas, .fa-brands {
  font-style: normal; }

.fa-phone-circle::before {
  content: "\e11b"; }

.fa-user-lock::before {
  content: "\f502"; }

.fa-user::before {
  content: "\f007"; }

.fa-rss::before {
  content: "\f09e"; }

.fa-circle-info::before {
  content: "\f05a"; }

.fa-file-arrow-down::before {
  content: "\f56d"; }

.fa-wrench::before {
  content: "\f0ad"; }

.fa-wrench::before {
  content: "\f0ad"; }

.fa-cart-shopping::before {
  content: "\f07a"; }

.fa-caret-down::before {
  content: "\f0d7"; }

.fa-caret-up::before {
  content: "\f0d8"; }

.fa-caret-left::before {
  content: "\f0d9"; }

.fa-caret-right::before {
  content: "\f0da"; }

.fa-earth-americas::before {
  content: "\f57d"; }

.fa-magnifying-glass::before {
  content: "\f002"; }

.fa-circle-plus::before {
  content: "\f055"; }

.fa-chevron-left::before {
  content: "\f053"; }

.fa-chevron-right::before {
  content: "\f054"; }

.fa-arrow-right::before {
  content: "\f061"; }

.fa-phone::before {
  content: "\f095"; }

.fa-comment-dots::before {
  content: "\f4ad"; }

.fa-location-dot::before {
  content: "\f3c5"; }

.fa-globe::before {
  content: "\f0ac"; }

.fa-envelope::before {
  content: "\f0e0"; }

.fa-phone-arrow-up-right::before {
  content: "\e224"; }

.fa-phone-rotary::before {
  content: "\f8d3"; }

.fa-arrow-down-to-bracket::before {
  content: "\e094"; }

.fa-arrow-down::before {
  content: "\f063"; }

.fa-chevron-down::before {
  content: "\f078"; }

.fa-minus::before {
  content: "\f068"; }

.fa-plus::before {
  content: "\2b"; }

.fa-xmark::before {
  content: "\f00d"; }

.fa-lock::before {
  content: "\f023"; }

.fa-facebook-f::before {
  content: "\f39e"; }

.fa-facebook::before {
  content: "\f09a"; }

.fa-google::before {
  content: "\f1a0"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-ellipsis-vertical::before {
  content: "\f142"; }

.fa-filter::before {
  content: "\f0b0"; }

.fa-arrow-right-to-arc::before {
  content: "\e4b2"; }

.fa-arrow-up::before {
  content: "\f062"; }

.fa-house::before {
  content: "\f015"; }

.fa-long-arrow-left::before {
  content: "\f177"; }

.fa-file-pdf::before {
  content: "\f1c1"; }

.fa-linkedin-in::before {
  content: "\f0e1"; }

.fa-computer-mouse-scrollwheel::before {
  content: "\f8cd"; }

.fa-angles-right:before {
  content: "\f101"; }

.fa-angles-left:before {
  content: "\f100"; }

.fa-arrow-up-to-line:before {
  content: "\f341"; }

.fa-paper-plane-top:before {
  content: "\e20a"; }

.fa-money-check-dollar-pen:before {
  content: "\f873"; }

.fa-seedling:before {
  content: "\f4d8"; }

.fa-cloud:before {
  content: "\f0c2"; }

.fa-people-group:before {
  content: "\e533"; }

.fa-down-to-bracket:before {
  content: "\e4e7"; }

.fa-phone-arrow-up-right:before {
  content: "\e224"; }

.fa-timer:before {
  content: "\e29e"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-list:before {
  content: "\f03a"; }

.fa-arrow-down-long:before {
  content: "\f175"; }

.fa-fax:before {
  content: "\f1ac"; }

.fa-th-large:before {
  content: "\f009"; }

.fa-x:before {
  content: "\58"; }

.fa-bars-filter:before {
  content: "\e0ad"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-arrow-up-long:before {
  content: "\f176"; }

.fa-arrow-up-down:before {
  content: "\f175"; }

.fa-download:before {
  content: "\f019"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-file-pdf:before {
  content: "\f1c1"; }

.fa-long-arrow-right::before {
  content: "\f178"; }

.fa-arrow-right::before {
  content: "\f061"; }

.fa-arrow-left::before {
  content: "\f060"; }

.fa-dollar-sign::before {
  content: "\24"; }

.fa-briefcase::before {
  content: "\f0b1"; }

.fa-timer::before {
  content: "\e29e"; }

.fa-file-certificate::before {
  content: "\f5f3"; }

.fa-check::before {
  content: "\f00c"; }

main {
  padding-top: 80px; }
  @media screen and (max-width: 1024px) {
    main {
      padding-top: 60px; } }

body.overlay-bg {
  @apply overflow-hidden relative {} }
  body.overlay-bg::after {
    content: '';
    transition: 0.45s cubic-bezier(0.6, 0, 0.4, 1);
    @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full bg-dark opacity-60 {} }

body.vi-vn {
  font-family: 'Inter'; }
  body.vi-vn .block-title {
    font-family: 'Inter';
    font-weight: 600; }
    body.vi-vn .block-title * {
      font-family: 'Inter';
      font-weight: 600; }
  body.vi-vn footer .bottom-row {
    @apply center-item {} }
    body.vi-vn footer .bottom-row .wrap-left {
      @apply justify-between {} }
    body.vi-vn footer .bottom-row .col {
      @apply w-full {} }
    body.vi-vn footer .bottom-row .policy-nav {
      @apply justify-center {} }
      @media screen and (max-width: 768px) {
        body.vi-vn footer .bottom-row .policy-nav {
          @apply flex-wrap {} }
          body.vi-vn footer .bottom-row .policy-nav li {
            @apply mb-2 {} } }
  body.vi-vn .top-banner .txt-wrap .title {
    font-family: 'Inter'; }
  body.vi-vn .navbar-primary-menu .nav > .drop-down > .title a {
    font-weight: 600; }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      body.vi-vn .navbar-primary-menu .nav > .drop-down > .title a {
        font-size: clamp(13px, 0.83333rem, 16px);
        padding: 0 0.83333rem; } }
    @media screen and (min-width: 1024px) and (max-width: 1100px) {
      body.vi-vn .navbar-primary-menu .nav > .drop-down > .title a {
        font-size: clamp(13px, 0.83333rem, 16px);
        padding: 0 0.72917rem; } }
  body.vi-vn .navbar-primary-menu .nav > .nav-link > a {
    font-weight: 600; }
    @media screen and (min-width: 1024px) and (max-width: 1280px) {
      body.vi-vn .navbar-primary-menu .nav > .nav-link > a {
        font-size: clamp(13px, 0.83333rem, 16px);
        padding: 0 0.83333rem; } }
    @media screen and (min-width: 1024px) and (max-width: 1100px) {
      body.vi-vn .navbar-primary-menu .nav > .nav-link > a {
        padding: 0 0.72917rem; } }

.four-swiper, .trinity-swiper, .single-swiper, .double-swiper {
  @apply relative {} }

.main-menu-toggle {
  @apply hidden {} }
  @media screen and (max-width: 1024px) {
    .main-menu-toggle {
      @apply flex items-center pl-8 {} } }
  @media screen and (max-width: 768px) {
    .main-menu-toggle {
      @apply pl-4 {} } }

iframe {
  @apply block my-4 mx-auto {} }

.frm-thanks {
  @apply my-16 p-8 text-center {}  margin: 0 auto;
  width: 500px; }
  .frm-thanks h3, .frm-thanks h4, .frm-thanks h5 {
    @apply text-primary font-bold text-primary text-16 mb-3 {} }
  .frm-thanks p, .frm-thanks span {
    @apply text-14 text-grey-333 font-normal {} }
  @media screen and (max-width: 1024px) {
    .frm-thanks {
      width: 320px; } }

.tab-panel {
  @apply hidden {} }
  .tab-panel.active {
    @apply block {} }

.edit-link {
  width: 30px;
  height: 30px;
  pointer-events: auto; }
  .edit-link .fa-pencil {
    width: 18px;
    height: 18px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg  viewBox='0 0 18 18'  xmlns='http://www.w3.org/2000/svg'><path d='M6.35473 15.7485L2.27202 11.6658L12.1468 1.79106L16.2295 5.87378L6.35473 15.7485ZM1.89039 12.385L5.63556 16.1302L0.0205078 18L1.89039 12.385ZM17.4852 4.62344L16.7771 5.33159L12.6889 1.24347L13.3971 0.535326C14.1104 -0.178442 15.2672 -0.178442 15.9805 0.535326L17.4852 2.04001C18.1934 2.75572 18.1934 3.90795 17.4852 4.62344Z' fill='#094594'/></svg>"); }

.custom-select {
  width: 100%; }
  .custom-select select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("../img/icon/chevron-down.png") no-repeat calc(100% - 15px), rgba(255, 255, 255, 0.5) !important;
    background-position: 95% 50%; }

@media screen and (max-width: 768px) {
  .table-responsive {
    overflow-x: auto; } }

.table-responsive table {
  @apply w-full {} }
  @media screen and (max-width: 768px) {
    .table-responsive table {
      white-space: nowrap; } }
  .table-responsive table thead tr th {
    @apply bg-primary text-white text-16 font-bold text-left  py-4  px-6 {} }
    @media screen and (min-width: 1024px) {
      .table-responsive table thead tr th:first-child {
        @apply pl-12 {} } }
  .table-responsive table tbody tr:nth-child(even) {
    background: #f5f5f5; }
  .table-responsive table tbody tr td {
    @apply py-4 px-6 {} }
    .table-responsive table tbody tr td:first-child {
      width: 25%; }
    @media screen and (min-width: 1024px) {
      .table-responsive table tbody tr td {
        @apply pl-12 {} } }
    .table-responsive table tbody tr td:last-child {
      width: 25%;
      @apply text-right pr-12 {} }
      .table-responsive table tbody tr td:last-child .btn-view-more span {
        color: #af9a56; }

.scollbar-wrap {
  height: 300px;
  padding-right: 15px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #A0C4F4 #cccccc; }
  .scollbar-wrap::-webkit-scrollbar {
    background: #cccccc;
    width: 3px; }
  .scollbar-wrap::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #cccccc;
    background-color: #cccccc; }
  .scollbar-wrap::-webkit-scrollbar-thumb {
    background-color: #A0C4F4; }
  @media screen and (max-width: 1024px) {
    .scollbar-wrap {
      height: auto; } }

.qradio input {
  background-repeat: no-repeat;
  background-position: center;
  appearance: none;
  @apply block {}  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>"); }
  .qradio input:checked {
    background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23ff0000' width='20px' height='20px'><path d='M0 0h24v24H0z' fill='none'/><path d='M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zm0-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'/></svg>"); }

.dash-line {
  border-top: 2px solid #000; }

.dash-grey {
  border-bottom: 1px solid #ccc; }

.flex-wrap {
  display: flex;
  justify-content: center; }

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px; }
  .login-page .login-panel .sitelogo {
    text-align: center;
    margin-bottom: 40px;
    width: 100%; }
  .login-page .login-panel img {
    height: 80px; }
  .login-page .login-panel .headtitle {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .login-page .login-panel .headtitle:after {
      content: '';
      margin: 10px auto; }

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0; } }

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px; } }

.login-form {
  width: 100%;
  margin: 0 auto; }
  .login-form .fa-exclamation-triangle {
    @apply absolute top-full left-0 text-12 text-primary pt-1 {} }
  .login-form .panel-heading {
    display: none; }
  .login-form .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    border-left: 3px solid #8c0000;
    padding-left: 10px;
    margin-bottom: 30px; }
  .login-form .module-title {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 20px; }
  .login-form a {
    color: #8c0000; }
  .login-form .form-group {
    margin-bottom: 20px;
    position: relative; }
    .login-form .form-group label {
      color: #999999;
      text-transform: uppercase;
      font-size: 0rem;
      display: block;
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #e6e6e6;
      @apply text-14  text-grey-333 {} }
      .login-form .form-group label .fa {
        font-size: 1.125rem;
        line-height: 40px; }
  .login-form input {
    width: 350px;
    font-size: .875rem;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 10px 30px 10px 60px;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    .login-form input::placeholder {
      font-size: 15px; }
    .login-form input:active, .login-form input:hover, .login-form input:focus {
      border-color: #999999; }
    @media screen and (max-width: 400px) {
      .login-form input {
        width: 300px; } }
  .login-form a {
    display: inline-block;
    color: #000;
    padding: 10px 0;
    margin-right: 15px; }
    .login-form a:hover {
      color: #8c0000; }
  .login-form .forget {
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 30px; }
    .login-form .forget input[type='checkbox'] {
      @apply opacity-0 relative {} }
      .login-form .forget input[type='checkbox']:checked ~ label::before {
        content: '\F0132'; }
    .login-form .forget label {
      color: #333333;
      font-weight: 300;
      width: auto;
      height: auto;
      text-align: left;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 0.875rem;
      text-transform: none;
      position: relative;
      -webkit-transition: all, 0.5s;
      transition: all, 0.5s; }
      .login-form .forget label:before {
        font-family: 'Material Design Icons';
        content: '\F0131';
        font-size: 20px;
        display: block;
        line-height: 14px;
        font-size: 16px;
        color: #333333;
        position: absolute;
        top: 2px;
        left: 1px;
        -webkit-transition: all, 0.5s;
        transition: all, 0.5s; }
      .login-form .forget label:hover {
        color: #333333; }
  .login-form .btn-login {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    background: #8c0000;
    display: block;
    border-radius: 5px;
    overflow: hidden; }
    .login-form .btn-login:hover::after {
      transform: translateX(3px); }
    .login-form .btn-login:before {
      background: #c61017; }
    .login-form .btn-login:after {
      transition: .3s all  ease-in-out;
      font: normal normal normal 24px/1 Material Design Icons;
      content: '\F0142';
      position: absolute;
      top: 12px;
      right: 15px; }
  .login-form input[type='reset'] {
    display: none; }
  .login-form input[type="submit"] {
    line-height: 100%;
    cursor: pointer;
    width: 100%;
    color: #fff !important;
    background: #171e26 !important;
    padding: 15px 60px;
    border: none;
    text-transform: uppercase;
    font-size: 1rem; }
  .login-form .go-signup {
    text-align: center;
    margin-top: 15px; }
    .login-form .go-signup a {
      display: block;
      clear: both;
      color: #8c0000; }
      .login-form .go-signup a:hover {
        color: #000; }

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%; } }

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px; }
  .login-bg .login-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .login-bg .login-img .login-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.5;
      z-index: 1; }
  .login-bg img {
    height: 100%;
    width: auto;
    max-width: unset;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .login-bg .login-des {
    position: relative;
    z-index: 1;
    color: #fff; }
    .login-bg .login-des .title {
      font-size: 2.875rem;
      font-weight: 300;
      margin-bottom: 20px; }
    .login-bg .login-des .module-title {
      font-size: 1.625rem;
      font-weight: 200;
      opacity: 0.9; }
    .login-bg .login-des .des {
      margin-top: 180px;
      font-size: 1.25rem; }
      .login-bg .login-des .des span {
        opacity: 0.8;
        font-weight: 200;
        display: inline-block;
        margin-right: 10px; }
      .login-bg .login-des .des a {
        display: inline-block;
        opacity: 1;
        font-weight: 300;
        font-size: 2rem;
        color: #fff; }
        .login-bg .login-des .des a:after {
          content: '\f105';
          font-family: 'fontawesome';
          margin-left: 5px; }
        .login-bg .login-des .des a:hover {
          color: #8c0000; }

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .login-bg {
    padding: 30px; } }

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1); } }

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem; } }

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px; } }

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem; } }

.panel-primary span {
  color: #eb0c22;
  font-style: italic; }

#ctl00_mainContent_login1_LoginCtrl_lnkPasswordRecovery, #ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink {
  display: none; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem;
  display: block;
  line-height: 1.25; }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.login-box, .wrap-register, .wrap-recover, .wrap-resetpass {
  max-width: 400px;
  margin: 60px auto;
  padding: 1.875rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.03); }

.login-logo, .pagetitle, .postname, .wrap-recover h1 {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.375;
  font-weight: 700;
  margin-bottom: 1.25rem; }

.posttitle, .wrap-resetpass h1 {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
  line-height: 1.375; }

.postname {
  text-align: left; }

.login-box-msg {
  font-size: 1.125rem;
  margin-bottom: 1rem; }

.login-box input[type=text], .login-box input[type=password], .login-box select, .wrap-register input[type=text], .wrap-register input[type=password], .wrap-register select, .account-form input[type=text], .account-form input[type=password], .account-form select, .address-form input[type=text], .address-form input[type=password], .address-form select, .wrap-recover input[type=text], .wrap-recover input[type=password], .wrap-recover select, .wrap-resetpass input[type=text], .wrap-resetpass input[type=password], .wrap-resetpass select {
  border: 0 !important;
  border-bottom: 1px solid #d7d7d7 !important;
  padding: 12px 12px  12px 50px !important;
  width: 100% !important;
  height: auto !important; }

.login-box input[type=submit], .wrap-register input[type=submit], .account-form input[type=submit], .address-form input[type=submit], .wrap-recover input[type=submit], .wrap-resetpass input[type=submit] {
  height: 2.5rem;
  width: 100%;
  font-size: 1rem;
  color: #fff;
  border: 0;
  background: #333;
  font-weight: 700;
  display: block;
  margin-top: 30px; }

.login-box input:disabled, .wrap-register input:disabled, .account-form input:disabled, .address-form input:disabled, .wrap-recover input:disabled, .wrap-resetpass input:disabled {
  color: #666;
  background-color: transparent;
  font-style: italic; }

.wrap-register .settingrow > *, .account-form .settingrow > *, .address-form .settingrow > *, .wrap-recover .settingrow > *, .wrap-resetpass .settingrow > * {
  flex: 0 0 100%;
  max-width: 100%; }

.wrap-register .form-group, .account-form .form-group, .address-form .form-group, .wrap-recover .form-group, .wrap-resetpass .form-group {
  margin-bottom: 20px; }
  .wrap-register .form-group label, .account-form .form-group label, .address-form .form-group label, .wrap-recover .form-group label, .wrap-resetpass .form-group label {
    line-height: 1.5; }

.passwordrecovery input[type=submit], .wrap-register input[type=submit] {
  margin: 0 auto; }

.fa-exclamation-triangle {
  font-size: 12px;
  color: red;
  line-height: 2; }
  .fa-exclamation-triangle::before {
    display: none; }

.profile-container {
  padding: 3rem 15px; }

.regcomplete span {
  color: #6cc070;
  display: block;
  text-align: center;
  margin-bottom: 20px; }

.login-box .input-group {
  @apply relative {} }

.login-box .input-group-append {
  @apply absolute-y left-0 center-item {}  width: 40px;
  height: 40px; }

.login-box .social-login {
  @apply hidden {} }

.login-box .check-login-submit {
  @apply center-item mt-5 {} }

.login-box .icheck-primary label {
  @apply ml-1 {} }

.pad-6 {
  padding: 2.08333rem 0; }

@screen lg {
  .pad-6 {
    padding: 2.60417rem 0; } }

.pad-8 {
  padding: 2.08333rem 0; }

@screen lg {
  .pad-8 {
    padding: 2.60417rem 0 3.64583rem; } }

.tab-item {
  @apply hidden {} }
  .tab-item.active {
    @apply block {} }

.tabs-option {
  @apply hidden {} }
  .tabs-option.active {
    @apply block {} }

.years-wrap {
  @apply mb-4 end-item {} }
  .years-wrap .custom-select {
    @apply w-auto {} }
    .years-wrap .custom-select select {
      padding-right: 50px;
      @apply text-blue-0A2ECC {}      font-size: 1.04167rem; }
    .years-wrap .custom-select option {
      @apply text-blue-0A2ECC {}      font-size: 1.04167rem; }

.fullcontent {
  @apply text-grey-333 font-normal leading-normal pt-4 {}  font-size: 16px; }
  .fullcontent * {
    @apply text-grey-333 font-normal leading-normal {}    font-size: 16px; }
  .fullcontent p {
    @apply mb-3 {} }
  .fullcontent h2 {
    @apply font-bold my-3 {}    font-size: 24px; }
    .fullcontent h2 * {
      @apply font-bold {}      font-size: 24px; }
  .fullcontent h3 {
    @apply font-bold my-2 {}    font-size: 20px; }
    .fullcontent h3 * {
      @apply font-bold {}      font-size: 20px; }
  .fullcontent h4 {
    @apply font-bold my-2 pl-6 {}    font-size: 16px; }
    .fullcontent h4 * {
      @apply font-bold {}      font-size: 16px; }
  .fullcontent strong {
    @apply font-bold {} }
  .fullcontent a {
    @apply text-blue-0A2ECC {} }
  .fullcontent img, .fullcontent iframe {
    @apply block my-4 mx-auto {} }
  .fullcontent ul {
    @apply mb-2 {} }
    .fullcontent ul li {
      @apply start-item mb-1 {} }
      .fullcontent ul li::before {
        content: '';
        @apply inline-block z-10  transition  bg-blue-0A2ECC rounded-full mr-4 mt-2 {}        width: 6px;
        height: 6px;
        min-width: 6px; }

@screen lg {
  .fullcontent ul li::before {
    @apply mr-5 {} } }
  .fullcontent .item {
    @apply text-center {} }
    .fullcontent .item .icon {
      @apply mx-auto mb-3 center-item {}      height: 52px;
      width: auto; }
      .fullcontent .item .icon img {
        object-fit: contain; }

.top-nav-wrap ul {
  @apply center-item {} }
  .top-nav-wrap ul li {
    padding: 0 20px; }

@screen lg {
  .top-nav-wrap ul li:hover a {
    @apply text-blue-0A2ECC {} } }
    .top-nav-wrap ul li.active a {
      @apply border-blue-0A2ECC text-blue-0A2ECC {} }
  .top-nav-wrap ul a {
    @apply text-grey-666 font-bold leading-none center-item transition  border-b  border-transparent {}    font-size: 1.04167rem;
    padding-bottom: 0.72917rem; }

.table-wrap table thead th {
  @apply text-white font-bold bg-blue-0A2ECC text-left border-r border-r-grey-cc {}  padding: 0.83333rem 1.5625rem;
  font-size: 0.83333rem; }
  .table-wrap table thead th * {
    font-size: 0.83333rem; }
  @media screen and (max-width: 576px) {
    .table-wrap table thead th {
      padding: 0.625rem 1.04167rem;
      font-size: 0.9375rem; }
      .table-wrap table thead th * {
        font-size: 0.9375rem; } }

.table-wrap table tbody tr:nth-child(even) {
  @apply bg-grey-f5 {} }

.table-wrap table tbody tr td {
  @apply border-r border-r-grey-cc text-grey-333 {}  padding: 0.83333rem 1.5625rem;
  font-size: 0.83333rem; }
  .table-wrap table tbody tr td a {
    @apply cursor-pointer {} }
  .table-wrap table tbody tr td * {
    font-size: 0.83333rem;
    @apply text-grey-333 {} }
  @media screen and (max-width: 576px) {
    .table-wrap table tbody tr td {
      padding: 0.625rem 1.04167rem;
      font-size: 0.9375rem; }
      .table-wrap table tbody tr td * {
        font-size: 0.9375rem; } }
  .table-wrap table tbody tr td em {
    font-size: 1.25rem;
    @apply text-grey-666 {} }
    @media screen and (max-width: 1024px) {
      .table-wrap table tbody tr td em {
        font-size: 20px; } }

@media screen and (max-width: 1024px) {
  .table-wrap {
    @apply overflow-x-auto {} }
    .table-wrap table {
      @apply whitespace-nowrap {} } }

footer {
  @apply bg-blue-E7F1F8 {}  padding: 2.08333rem 0; }

@screen lg {
  footer {
    padding: 2.08333rem 0; } }

@screen lg {
  footer .container {
    padding: 0 5.20833rem; } }

@screen lg {
  footer .top-row {
    margin: 0 -37px; }
    footer .top-row .col {
      padding: 0 37px;
      margin-bottom: 17px; }
      footer .top-row .col:not(:last-child) {
        border-right: 1px solid #d9d9d9; } }
  @media screen and (max-width: 1024px) {
    footer .bottom-row {
      @apply mb-0 {} }
      footer .bottom-row .col {
        @apply mb-0 {} } }
  @media screen and (max-width: 1024px) {
    footer .wrap-left {
      @apply col-ver {} } }
  footer .open-time-wrap .headline {
    @apply mb-1 {} }
  footer .open-time-wrap .hotline-title {
    @apply mb-2 {} }
  footer .open-time-wrap .txt {
    @apply text-blue-006BEA font-bold leading-tight mb-2 {} }
    footer .open-time-wrap .txt * {
      @apply text-blue-006BEA font-bold {} }
  footer .headline {
    @apply text-dark mb-5 {}    font-size: 16px; }
    footer .headline * {
      @apply text-dark {}      font-size: 16px; }
    @media screen and (max-width: 1600px) {
      footer .headline {
        font-size: 1.04167rem; }
        footer .headline * {
          font-size: 1.04167rem; } }
  footer ol li {
    @apply text-dark font-normal mb-1 {}    font-size: clamp(14px, 0.9375rem, 18px); }
    footer ol li * {
      font-size: clamp(14px, 0.9375rem, 18px); }
    @media screen and (min-width: 1600px) {
      footer ol li {
        font-size: clamp(12px, 0.72917rem, 14px); }
        footer ol li * {
          font-size: clamp(12px, 0.72917rem, 14px); } }
  footer .copyright {
    @apply text-dark font-normal {}    font-size: 0.9375rem; }

@screen lg {
  footer .copyright {
    @apply mb-4 {} } }
    footer .copyright * {
      font-size: 0.9375rem; }
    @media screen and (min-width: 1600px) {
      footer .copyright {
        font-size: 0.72917rem; }
        footer .copyright * {
          font-size: 0.72917rem; } }
    @media screen and (max-width: 1024px) {
      footer .copyright {
        @apply text-center my-6 {} } }
  footer .footer-nav {
    @apply end-item {} }
    footer .footer-nav li {
      @apply border-r border-r-dark leading-none {} }
      footer .footer-nav li:first-child a {
        @apply pl-0 {} }
      footer .footer-nav li:hover a {
        @apply underline {} }
      footer .footer-nav li.active a {
        @apply text-blue-0A2ECC underline {} }
    footer .footer-nav a {
      @apply text-dark font-normal leading-none center-item {}      font-size: 0.9375rem; }
      footer .footer-nav a * {
        font-size: 0.9375rem; }
      @media screen and (min-width: 1600px) {
        footer .footer-nav a {
          font-size: 0.72917rem; }
          footer .footer-nav a * {
            font-size: 0.72917rem; } }
  @media screen and (max-width: 768px) {
    footer .footer-menu {
      @apply flex-wrap {} }
      footer .footer-menu li {
        @apply mb-2 {} } }
  footer .footer-menu a {
    padding: 0 0.72917rem; }
  footer .policy-nav-wrap {
    @apply mt-auto {} }
  footer .policy-nav {
    @apply mt-auto {} }
    @media screen and (max-width: 1024px) {
      footer .policy-nav {
        @apply justify-center {} } }
    footer .policy-nav a {
      padding: 0 0.3125rem; }
    footer .policy-nav li:last-child a {
      @apply pr-0 {} }
    footer .policy-nav li:last-child {
      border-right: 0; }
  footer .social-network {
    @apply start-item {} }
    footer .social-network .ModuleContent {
      @apply start-item {} }

@screen lg {
  footer .social-network {
    @apply ml-[20px] {} } }
    @media screen and (max-width: 1024px) {
      footer .social-network {
        @apply my-6 {} } }
    footer .social-network a {
      @apply center-item {}      width: 32px;
      height: 32px; }
      footer .social-network a:hover em {
        @apply text-blue-0A2ECC {} }
      footer .social-network a em {
        @apply text-grey-333 leading-none transition {}        font-size: 16px; }

.side-tool-nav {
  @apply fixed right-[20px] bottom-[50px] z-50 {} }
  .side-tool-nav ul {
    @apply col-ver {} }
  .side-tool-nav li:not(:last-child) {
    @apply mb-[10px] {} }
  .side-tool-nav a {
    @apply center-item bg-white rounded-full overflow-hidden {}    width: 2.60417rem;
    height: 2.60417rem;
    transition: .35s all  ease-in-out;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09); }
    .side-tool-nav a:hover {
      @apply bg-blue-0A2ECC {} }
      .side-tool-nav a:hover em {
        @apply text-white {} }
    .side-tool-nav a em {
      font-size: 1.25rem;
      @apply leading-none text-blue-0A2ECC {}      transition: .35s all  ease-in-out; }

.header-site {
  @apply fixed top-0  left-0 w-full h-[60px] bg-white border-b border-grey-e4 {}  z-index: 121; }

@screen lg {
  .header-site {
    @apply h-[80px] {} } }
  .header-site .container-fluid {
    @apply relative gap-item  h-full {} }

@screen lg {
  .header-site .container-fluid {
    padding: 0 3.125rem; } }
  .header-site .header-wrap-left {
    @apply end-item h-full {}    align-items: center; }

.nav-brand .logo-desktop {
  @apply hidden {} }

@screen xl {
  .nav-brand .logo-desktop {
    @apply block {} } }

.nav-brand .logo-mobile {
  @apply block {} }

@screen xl {
  .nav-brand .logo-mobile {
    @apply hidden {} } }

.nav-brand a {
  display: flex;
  height: 40px;
  width: auto; }
  .nav-brand a img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

@media screen and (max-width: 1024px) {
  .header-btn-wrap {
    @apply mt-6 hidden {} }
    .header-btn-wrap .Module {
      @apply w-full {} } }

.header-btn-wrap ul {
  @apply center-item {} }
  @media screen and (max-width: 1024px) {
    .header-btn-wrap ul {
      @apply -mx-3 {} }
      .header-btn-wrap ul li {
        @apply w-1/2 px-3 {} } }

.header-btn-wrap a {
  @apply center-item {}  padding: 0 0.88542rem; }
  @media screen and (max-width: 1024px) {
    .header-btn-wrap a {
      height: 36px;
      @apply border-grey-333 border rounded-full {} } }
  @media screen and (max-width: 576px) {
    .header-btn-wrap a {
      padding: 0 0.625rem; } }
  .header-btn-wrap a em {
    font-size: 1.25rem;
    @apply leading-none text-grey-333 {} }
    @media screen and (max-width: 1024px) {
      .header-btn-wrap a em {
        font-size: 20px; } }
    @media screen and (max-width: 576px) {
      .header-btn-wrap a em {
        font-size: 18px; } }

.menu-toggle {
  @apply flex {} }

@screen lg {
  .menu-toggle {
    @apply hidden {} } }

.mobile-menu-wrap {
  @apply hidden {} }
  @media screen and (max-width: 1024px) {
    .mobile-menu-wrap {
      @apply fixed left-0 top-[60px] z-40  h-full bg-white opacity-0 pointer-events-none block {}      transform: translateX(-100%);
      transition: 0.45s cubic-bezier(0.6, 0, 0.4, 1);
      padding: 1.04167rem 1.5625rem 0 0.78125rem;
      align-items: flex-start;
      width: 320px; }
      .mobile-menu-wrap.is-open {
        @apply opacity-100 pointer-events-auto {}        transform: none;
        z-index: 121; } }

.navbar-primary-menu {
  @apply h-full {} }
  @media screen and (max-width: 1024px) {
    .navbar-primary-menu {
      @apply h-auto hidden {} } }
  .navbar-primary-menu ul {
    @apply h-full {} }

@screen lg {
  .navbar-primary-menu ul {
    @apply end-item {} } }
    @media screen and (max-width: 1024px) {
      .navbar-primary-menu ul {
        @apply col-hor {} } }
    .navbar-primary-menu ul li {
      @apply center-item {} }

@screen lg {
  .navbar-primary-menu ul li {
    @apply h-full {} } }
  .navbar-primary-menu .nav > .nav-link {
    @apply relative {} }
    @media screen and (max-width: 1024px) {
      .navbar-primary-menu .nav > .nav-link > a {
        @apply uppercase {} }
      .navbar-primary-menu .nav > .nav-link > .title a {
        @apply uppercase {} } }
  .navbar-primary-menu .nav-link {
    @apply static {} }
    .navbar-primary-menu .nav-link.current-page > a {
      @apply text-blue-0A2ECC {} }
      .navbar-primary-menu .nav-link.current-page > a::after {
        @apply w-full {} }
    .navbar-primary-menu .nav-link.current-page > .title > a {
      @apply text-blue-0A2ECC {} }
      .navbar-primary-menu .nav-link.current-page > .title > a::after {
        @apply w-full {} }
    @media screen and (max-width: 1024px) {
      .navbar-primary-menu .nav-link {
        @apply w-full justify-start py-2 {} } }
    .navbar-primary-menu .nav-link a {
      @apply text-grey-151 font-bold text-xs uppercase center-item relative {} }
      @media screen and (max-width: 1024px) {
        .navbar-primary-menu .nav-link a {
          @apply normal-case {} } }
      .navbar-primary-menu .nav-link a::after {
        content: '';
        @apply block absolute-x pointer-events-none  bottom-[-1px] z-10  transition bg-blue-0A2ECC w-0 {}        height: 1px; }

@screen lg {
  .navbar-primary-menu .nav-link a {
    @apply h-full {}    padding: 0 1.5625rem;
    font-size: 0.83333rem; } }
  .navbar-primary-menu .drop-down {
    @apply flex-col {} }
    .navbar-primary-menu .drop-down .title {
      @apply w-full start-item {} }
      @media screen and (max-width: 1024px) {
        .navbar-primary-menu .drop-down .title {
          @apply gap-item w-full {} } }

@screen lg {
  .navbar-primary-menu .drop-down .title {
    @apply h-full {} } }
      .navbar-primary-menu .drop-down .title em {
        @apply end-item font-bold {}        font-size: 20px;
        width: 40px; }

@screen lg {
  .navbar-primary-menu .drop-down .title em {
    @apply hidden {} } }

@screen lg {
  .navbar-primary-menu .drop-down:hover > .nav-sub {
    @apply opacity-100 pointer-events-auto {} } }
  @media screen and (max-width: 1024px) {
    .navbar-primary-menu .nav-sub {
      @apply w-full items-start pt-2 hidden {} } }

@screen lg {
  .navbar-primary-menu .nav-sub {
    @apply absolute  left-0 h-auto col-hor w-max bg-white  opacity-0 pointer-events-none {}    transition: .45s all  ease-in-out;
    top: calc( 100% + 1px);
    padding: 0.72917rem 0; }
    .navbar-primary-menu .nav-sub::before, .navbar-primary-menu .nav-sub::after {
      content: '';
      @apply block absolute pointer-events-none  z-10  transition w-screen h-full bg-white bottom-0 {} }
    .navbar-primary-menu .nav-sub::before {
      @apply right-full {} }
    .navbar-primary-menu .nav-sub::after {
      @apply left-full {} } }
  .navbar-primary-menu .nav-sub a {
    @apply font-normal capitalize transition cursor-pointer {}    padding: 0.625rem 1.5625rem;
    font-size: 1.04167rem; }
    @media screen and (max-width: 1024px) {
      .navbar-primary-menu .nav-sub a {
        padding: 0.41667rem 0.78125rem;
        font-size: clamp(15px, 1.04167rem, 20px); } }
    .navbar-primary-menu .nav-sub a::after {
      @apply hidden {} }
    .navbar-primary-menu .nav-sub a:hover {
      @apply text-blue-006BEA {} }
  .navbar-primary-menu .nav-sub .nav-link {
    @apply w-full start-item {} }
  @media screen and (max-width: 1024px) {
    .navbar-primary-menu .nav-sub .nav-sub {
      @apply pt-0 {} } }

@screen lg {
  .navbar-primary-menu .nav-sub .nav-sub {
    @apply absolute top-0 left-full z-40 opacity-0 pointer-events-none {} }
    .navbar-primary-menu .nav-sub .nav-sub:before, .navbar-primary-menu .nav-sub .nav-sub::after {
      @apply opacity-0 {} } }
  @media screen and (max-width: 1024px) {
    .navbar-primary-menu .nav-link-sub {
      @apply justify-start {} }
      .navbar-primary-menu .nav-link-sub > a {
        @apply font-semibold {} }
      .navbar-primary-menu .nav-link-sub > .title a {
        @apply font-semibold {} }
      .navbar-primary-menu .nav-link-sub .nav-link-sub {
        @apply pl-5 py-0 {} }
        .navbar-primary-menu .nav-link-sub .nav-link-sub > a {
          @apply font-normal normal-case {} }
        .navbar-primary-menu .nav-link-sub .nav-link-sub > .title a {
          @apply font-normal normal-case {} } }
  .navbar-primary-menu .nav-link-sub.active > a {
    @apply text-blue-006BEA {} }
  .navbar-primary-menu .nav-link-sub.active > .title > a {
    @apply text-blue-006BEA {} }

.wrap-form {
  @apply relative {} }
  .wrap-form .row {
    box-shadow: none !important;
    margin: 0 -15px  !important; }
    .wrap-form .row .col {
      margin-bottom: 20px; }
  .wrap-form .fa-exclamation-triangle {
    @apply absolute font-normal {}    bottom: -22px;
    left: 20px;
    color: #f30000;
    font-size: 12px; }
  .wrap-form .form-group {
    @apply relative {}    margin-bottom: 20px !important; }
    .wrap-form .form-group input[type='text'], .wrap-form .form-group textarea, .wrap-form .form-group select {
      height: 46px;
      background: #ffffff;
      border: 1px solid #CCCCCC;
      border-radius: 10px;
      @apply text-grey-333 px-5 {}      font-size: 16px; }
      @media screen and (max-width: 1024px) {
        .wrap-form .form-group input[type='text'], .wrap-form .form-group textarea, .wrap-form .form-group select {
          height: 40px; } }
      .wrap-form .form-group input[type='text']:focus, .wrap-form .form-group textarea:focus, .wrap-form .form-group select:focus {
        outline: none; }
      .wrap-form .form-group input[type='text']::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
        @apply text-grey-333 font-normal {}        font-size: 16px; }
        @media screen and (max-width: 1024px) {
          .wrap-form .form-group input[type='text']::placeholder, .wrap-form .form-group textarea::placeholder, .wrap-form .form-group select::placeholder {
            @apply text-14 {} } }
    .wrap-form .form-group select option {
      @apply text-grey-333 {}      font-size: 16px; }
    .wrap-form .form-group textarea {
      padding: 16px 20px;
      height: 7rem; }

@screen lg {
  .wrap-form .form-group textarea {
    height: 90px; } }
  .wrap-form .frm-btnwrap .frm-btn input[type='submit'] {
    width: 180px;
    height: 46px;
    border-radius: 25px;
    @apply text-white  text-16 bg-blue-0A2ECC transition font-medium border border-blue-0A2ECC uppercase {} }
    @media screen and (max-width: 1024px) {
      .wrap-form .frm-btnwrap .frm-btn input[type='submit'] {
        height: 40px; } }
    .wrap-form .frm-btnwrap .frm-btn input[type='submit']:hover {
      @apply bg-white text-blue-0A2ECC {} }

.policy-check .title {
  @apply text-grey-333 font-bold block mb-2 {}  padding: 0 15px;
  font-size: 16px; }
  .policy-check .title::after {
    content: '*';
    @apply inline-block absolute pointer-events-none  z-10  transition text-primary {} }

.policy-check .chk-group input[type='checkbox'] {
  @apply absolute top-0 opacity-0 left-0 right-0  z-30 w-full h-full {} }
  .policy-check .chk-group input[type='checkbox']:checked ~ label:before {
    content: '\f14a'; }

.policy-check .chk-group label {
  @apply pointer-events-none  flex items-start text-grey-333 leading-normal {}  font-size: 16px; }
  .policy-check .chk-group label:before {
    font-family: 'Font Awesome 6 Pro';
    content: '\f0c8';
    width: 30px;
    height: 30px;
    display: inline-block;
    line-height: 30px;
    font-size: 30px;
    color: #CCCCCC;
    transition: .3s all  ease-in-out;
    margin-right: 20px;
    padding-top: 4px; }
    @media screen and (max-width: 1024px) {
      .policy-check .chk-group label:before {
        width: 24px;
        height: 24px;
        @apply mr-4 {} } }

.input-radio > label {
  @apply text-grey-333 font-bold block mb-3 {}  font-size: 16px; }

.input-radio ul {
  @apply col-hor {} }
  .input-radio ul li {
    @apply relative mb-2 {} }
  .input-radio ul input[type='radio'] {
    @apply absolute top-0 opacity-0 left-0 right-0  z-30 w-full h-full {} }
    .input-radio ul input[type='radio']:checked ~ label:before {
      background-image: url("data:image/svg+xml;charset=utf-8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='6' fill='%230A2ECC'/><circle cx='10' cy='10' r='9.5' stroke='%230A2ECC'/></svg>"); }
  .input-radio ul label {
    @apply pointer-events-none  flex items-start text-grey-333 leading-normal {}    font-size: 16px; }
    .input-radio ul label:before {
      content: '';
      background-repeat: no-repeat;
      background-position: center !important;
      appearance: none !important;
      outline: 0px !important;
      outline-offset: 0px !important;
      box-shadow: none !important;
      color: transparent;
      width: 20px;
      height: 20px;
      min-width: 20px;
      display: inline-block;
      line-height: 20px;
      font-size: 30px;
      margin: 4px 20px 0 0;
      background-image: url("data:image/svg+xml;charset=utf-8,<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='10' cy='10' r='9.5' stroke='%230A2ECC'/></svg>"); }

.frm-btn-reset {
  display: none; }

.frm-captcha {
  margin: 0 !important;
  display: inline-flex;
  flex-direction: row-reverse; }
  .frm-captcha img {
    height: 44px !important;
    max-width: auto;
    max-width: 150px; }
    @media screen and (max-width: 1200px) {
      .frm-captcha img {
        max-width: 150px; } }
  @media screen and (max-width: 576px) {
    .frm-captcha {
      flex-direction: column-reverse; } }
  .frm-captcha .frm-captcha-input {
    @apply mt-0 {}    margin-left: 50px; }
    @media screen and (max-width: 576px) {
      .frm-captcha .frm-captcha-input {
        margin-left: 0;
        @apply mt-4 {} } }
    .frm-captcha .frm-captcha-input label {
      display: none; }
    .frm-captcha .frm-captcha-input input {
      padding: 0 10px;
      margin-top: 0;
      height: 30px; }
      @media screen and (max-width: 1280px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 200px; } }
      @media screen and (max-width: 1200px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 150px; } }
      @media screen and (max-width: 1024px) {
        .frm-captcha .frm-captcha-input input {
          max-width: 200px; } }
      @media (max-width: 767.98px) {
        .frm-captcha .frm-captcha-input input {
          margin-left: 20px; } }
      @media (max-width: 576.98px) {
        .frm-captcha .frm-captcha-input input {
          margin-left: 0; } }
  .frm-captcha .rcRefreshImage {
    position: absolute;
    opacity: 1;
    cursor: pointer;
    height: 0;
    width: 0;
    right: -5px;
    top: 5px;
    color: #ffffff;
    z-index: 0;
    font-size: 0; }
    .frm-captcha .rcRefreshImage:before {
      font-family: 'Material Design Icons';
      position: absolute;
      top: 0;
      right: -40px;
      opacity: 1;
      z-index: 99;
      color: #9d9080;
      font-size: 30px;
      content: "\F0450";
      display: flex;
      justify-content: center;
      align-items: flex-start; }
      @media screen and (max-width: 576px) {
        .frm-captcha .rcRefreshImage:before {
          height: 50px; } }

@media screen and (max-width: 500px) {
  #ctl00_mainContent_ctl05_captcha_ctl00 {
    display: none; } }

#ctl00_mainContent_ctl02_captcha_ctl01 {
  position: relative; }

#ctl00_mainContent_ctl06_btnReset, #ctl00_mainContent_ctl05_captcha_ctl00, #ctl00_mainContent_ctl08_captcha_ctl00, #ctl00_mainContent_ctl07_captcha_ctl00 {
  display: none; }

#ctl00_mainContent_ctl06_txtCaptcha {
  margin-left: 10px; }

#ctl00_mainContent_ctl06_captcha_CaptchaLinkButton #text {
  display: none; }

.RadCaptcha {
  @apply relative {} }
  @media screen and (max-width: 767.5px) {
    .RadCaptcha {
      margin-left: 0; } }
  .RadCaptcha span {
    @apply hidden {} }
  .RadCaptcha #ctl00_mainContent_ctl04_captcha_ctl00 {
    display: block !important;
    position: absolute;
    left: 0;
    top: 100%;
    @apply text-primary text-12 {} }
  .RadCaptcha > div > div {
    display: flex;
    position: relative;
    flex-direction: column-reverse;
    height: 40px !important;
    margin-top: 0; }
    @media (max-width: 767.98px) {
      .RadCaptcha > div > div {
        margin-right: 0;
        width: 180px; } }

@media screen and (max-width: 1024px) {
  .language-wrap {
    @apply mr-3 {} } }

.language-list {
  @apply center-item {} }
  .language-list li {
    @apply inline-block leading-none {} }
    .language-list li:not(:last-child) {
      @apply border-r border-grey-bf {} }
    .language-list li.active a {
      @apply text-blue-0A2ECC font-bold {} }
    .language-list li a {
      @apply center-item text-grey-666 font-normal leading-none {}      padding: 0 10px;
      font-size: 1.04167rem; }

@screen lg {
  .language-list li a {
    font-size: 14px; } }

.flex-wrap {
  display: flex;
  justify-content: center; }

.login-page .login-panel {
  margin: 60px 0;
  background: #fff;
  border-radius: 5px; }
  .login-page .login-panel .sitelogo {
    text-align: center;
    margin-bottom: 40px;
    width: 100%; }
  .login-page .login-panel img {
    height: 80px; }
  .login-page .login-panel .headtitle {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px; }
    .login-page .login-panel .headtitle:after {
      content: '';
      margin: 10px auto; }

@media (max-width: 1199px) {
  .login-page .login-panel {
    margin: 60px 0; } }

@media (max-width: 543px) {
  .login-page .sitelogo {
    margin-bottom: 30px; } }

.login-form {
  width: 100%;
  margin: 0 auto; }
  .login-form .fa-exclamation-triangle {
    @apply absolute left-0 text-12 text-primary pt-1 {} }
  .login-form .panel-heading {
    display: none; }
  .login-form .title {
    font-size: 1.5rem;
    font-weight: 500;
    text-transform: uppercase;
    border-left: 3px solid #8c0000;
    padding-left: 10px;
    margin-bottom: 30px; }
  .login-form .module-title {
    font-size: 0.875rem;
    color: #666666;
    margin-bottom: 20px; }
  .login-form a {
    color: #8c0000; }
  .login-form .form-group {
    margin-bottom: 20px;
    position: relative; }
    .login-form .form-group label {
      color: #999999;
      text-transform: uppercase;
      font-size: 0rem;
      display: block;
      width: 50px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 0;
      border-right: 1px solid #e6e6e6;
      @apply text-14  text-grey-333 {} }
      .login-form .form-group label .fa {
        font-size: 1.125rem;
        line-height: 40px; }
  .login-form input {
    width: 350px;
    font-size: .875rem;
    border: 1px solid #d9d9d9;
    border-radius: 0;
    padding: 10px 30px 10px 60px;
    -webkit-transition: all, 0.5s;
    transition: all, 0.5s; }
    .login-form input::placeholder {
      font-size: 15px; }
    .login-form input:active, .login-form input:hover, .login-form input:focus {
      border-color: #999999; }
    @media screen and (max-width: 400px) {
      .login-form input {
        width: 300px; } }
  .login-form a {
    display: inline-block;
    color: #000;
    padding: 10px 0;
    margin-right: 15px; }
    .login-form a:hover {
      color: #8c0000; }
  .login-form .forget {
    font-size: 0.875rem;
    color: #999999;
    margin-bottom: 30px; }
    .login-form .forget input[type='checkbox'] {
      @apply relative {} }
      .login-form .forget input[type='checkbox']:checked ~ label::before {
        content: '\F0132'; }
    .login-form .forget label {
      color: #333333;
      font-weight: 300;
      width: auto;
      height: auto;
      text-align: left;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 0.875rem;
      text-transform: none;
      position: relative;
      -webkit-transition: all, 0.5s;
      transition: all, 0.5s; }
      .login-form .forget label:before {
        font-family: 'Material Design Icons';
        content: '\F0131';
        font-size: 20px;
        display: block;
        line-height: 14px;
        font-size: 16px;
        color: #333333;
        position: absolute;
        top: 2px;
        left: 1px;
        -webkit-transition: all, 0.5s;
        transition: all, 0.5s; }
      .login-form .forget label:hover {
        color: #333333; }
  .login-form .btn-login {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: relative;
    color: #fff;
    background: #8c0000;
    display: block;
    border-radius: 5px;
    overflow: hidden; }
    .login-form .btn-login:hover::after {
      transform: translateX(3px); }
    .login-form .btn-login:before {
      background: #c61017; }
    .login-form .btn-login:after {
      transition: .3s all  ease-in-out;
      font: normal normal normal 24px/1 Material Design Icons;
      content: '\F0142';
      position: absolute;
      top: 12px;
      right: 15px; }
  .login-form input[type='reset'] {
    display: none; }
  .login-form input[type="submit"] {
    line-height: 100%;
    cursor: pointer;
    width: 100%;
    color: #fff !important;
    background: #171e26 !important;
    padding: 15px 60px;
    border: none;
    text-transform: uppercase;
    font-size: 1rem; }
  .login-form .go-signup {
    text-align: center;
    margin-top: 15px; }
    .login-form .go-signup a {
      display: block;
      clear: both;
      color: #8c0000; }
      .login-form .go-signup a:hover {
        color: #000; }

@media (max-width: 543px) {
  .login-form .btn-login {
    width: 100%; } }

.login-bg {
  width: 60%;
  position: relative;
  overflow: hidden;
  padding: 50px;
  border-radius: 5px; }
  .login-bg .login-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
    .login-bg .login-img .login-overlay {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #000;
      opacity: 0.5;
      z-index: 1; }
  .login-bg img {
    height: 100%;
    width: auto;
    max-width: unset;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); }
  .login-bg .login-des {
    position: relative;
    z-index: 1;
    color: #fff; }
    .login-bg .login-des .title {
      font-size: 2.875rem;
      font-weight: 300;
      margin-bottom: 20px; }
    .login-bg .login-des .module-title {
      font-size: 1.625rem;
      font-weight: 200;
      opacity: 0.9; }
    .login-bg .login-des .des {
      margin-top: 180px;
      font-size: 1.25rem; }
      .login-bg .login-des .des span {
        opacity: 0.8;
        font-weight: 200;
        display: inline-block;
        margin-right: 10px; }
      .login-bg .login-des .des a {
        display: inline-block;
        opacity: 1;
        font-weight: 300;
        font-size: 2rem;
        color: #fff; }
        .login-bg .login-des .des a:after {
          content: '\f105';
          font-family: 'fontawesome';
          margin-left: 5px; }
        .login-bg .login-des .des a:hover {
          color: #8c0000; }

@media (max-width: 991px) {
  .login-bg {
    width: 100%;
    -webkit-box-ordinal-group: 2;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1; } }

@media (max-width: 767px) {
  .login-bg {
    padding: 30px; } }

@media (max-width: 991px) {
  .login-bg .login-img img {
    height: auto;
    width: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-img img {
    -webkit-transform: scale3d(1.1, 1.1, 1);
    -khtml-transform: scale3d(1.1, 1.1, 1);
    transform: scale3d(1.1, 1.1, 1); } }

@media (max-width: 543px) {
  .login-bg .login-img img {
    width: auto;
    height: 100%; } }

@media (max-width: 767px) {
  .login-bg .login-des .title {
    font-size: 2.25rem;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .login-bg .login-des .module-title {
    font-size: 1.25rem; } }

@media (max-width: 991px) {
  .login-bg .login-des .des {
    margin-top: 30px; } }

@media (max-width: 767px) {
  .login-bg .login-des .des {
    margin-top: 10px; } }

@media (max-width: 543px) {
  .login-bg .login-des .des a {
    font-size: 1.625rem; } }

.panel-primary span {
  color: #eb0c22;
  font-style: italic; }

#ctl00_mainContent_login1_LoginCtrl_lnkPasswordRecovery, #ctl00_mainContent_login1_LoginCtrl_lnkRegisterExtraLink {
  display: none; }

.pop-up-modal {
  padding: 0;
  border-radius: 15px;
  @apply w-full {}  max-width: 1162px; }

@screen lg {
  .pop-up-modal .frm-btnwrap {
    @apply absolute right-0 bottom-0 {} } }

@screen lg {
  .fancybox__slide {
    padding: 0 !important; } }

.fancybox__container {
  padding: 30px; }

.carousel__button.is-close {
  width: 52px;
  height: 52px;
  top: 0 !important;
  border-top-right-radius: 15px;
  @apply bg-blue-0A2ECC rounded-none overflow-hidden {} }
  @media screen and (max-width: 1024px) {
    .carousel__button.is-close {
      width: 40px;
      height: 40px; } }
  .carousel__button.is-close svg {
    color: white;
    font-size: 32px;
    height: 32px;
    width: 20px; }

.recruitment-detail-modal {
  padding: 1.5625rem 2.08333rem !important; }

@screen lg {
  .recruitment-detail-modal {
    padding: 2.86458rem 4.42708rem !important; } }
  .recruitment-detail-modal .block-title {
    font-size: 2.08333rem; }
  .recruitment-detail-modal .wrap-form .form-group {
    margin-bottom: 25px !important; }

@screen lg {
  .recruitment-detail-modal .wrap-form .form-group textarea {
    height: 160px; } }

.inquiries-modal, .whisteblowing-modal {
  padding: 1.5625rem 2.08333rem !important; }

@screen lg {
  .inquiries-modal, .whisteblowing-modal {
    padding: 2.08333rem 5.72917rem !important; } }
  .inquiries-modal .block-title, .whisteblowing-modal .block-title {
    margin-bottom: 1.5625rem; }

@screen sm {
  .whisteblowing-modal .check-stat ul {
    @apply start-item flex-wrap flex-row {}    justify-content: flex-start; } }

.whisteblowing-modal .check-stat ul li {
  width: 25%; }
  @media screen and (max-width: 768px) {
    .whisteblowing-modal .check-stat ul li {
      @apply w-1/2 {} } }
  @media screen and (max-width: 576px) {
    .whisteblowing-modal .check-stat ul li {
      @apply w-full {} } }

.search-page {
  padding: 3.125rem 0; }
  .search-page h1 {
    letter-spacing: -2px;
    margin-bottom: 20px;
    font-size: 45px;
    line-height: 55px;
    font-weight: 500;
    color: #333333; }
    @media (max-width: 992px) {
      .search-page h1 {
        font-size: 24px;
        line-height: 30px; } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .form-inline {
    @apply relative {} }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: "\f002";
      font-family: 'Font Awesome 6 Pro';
      top: 0;
      right: 10px;
      z-index: 1;
      font-size: 24px;
      color: #4285f4;
      width: 44px;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none; }
  .search-page .searchcontrols .frm-btn {
    position: absolute;
    top: 0;
    right: 10px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 20px;
    padding-right: 54px;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 44px;
    border-radius: 22px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    outline: none;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    right: 0;
    bottom: 0; }
  .search-page .text-danger {
    margin-top: 20px;
    font-size: 15px;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 30px; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 30px; }
  .search-page .searchresult {
    margin-bottom: 30px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 16px;
    border-radius: 8px; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 18px;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 10px; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 10px; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }
  .search-page .search-title {
    @apply text-24 text-grey-333 font-bold mb-4 block uppercase {} }
  .search-page .text-main {
    @apply font-normal text-18 text-26 normal-case {} }
  .search-page .searchresultsummary {
    @apply text-16 text-grey-333 font-semibold {} }
  .search-page .kw-desc {
    @apply font-bold text-blue-0A2ECC {} }

.search-toggle {
  padding: 0 0.88542rem;
  margin-left: 2.08333rem; }
  @media screen and (max-width: 576px) {
    .search-toggle {
      padding: 0 0.625rem;
      @apply ml-0 {} } }
  .search-toggle em {
    font-size: 1.25rem;
    @apply leading-none text-grey-333 {} }
    @media screen and (max-width: 1024px) {
      .search-toggle em {
        font-size: 20px; } }

.block-wrap-search {
  @apply relative mb-8 {} }
  .block-wrap-search input[type='submit'] {
    @apply absolute top-0 right-0 {} }
  .block-wrap-search .form-group::after {
    font-family: 'Font Awesome 6 Pro';
    content: '\f002';
    @apply block absolute pointer-events-none right-0 bottom-0 z-10  transition text-blue-0A2ECC center-item {}    font-size: 24px;
    height: 44px;
    width: 44px; }

.search-overlay {
  z-index: 1030;
  height: 25vh;
  top: 80px;
  @apply bg-white shadow-lg opacity-0 pointer-events-none transition-all fixed w-full flex items-center justify-center left-0 {} }
  @media (max-width: 1024px) {
    .search-overlay {
      height: 40vh;
      top: 60px; } }
  .search-overlay.active {
    @apply opacity-100 pointer-events-auto {} }
    .search-overlay.active .searchbox {
      transform: translateY(0%);
      @apply delay-100 opacity-100 {} }
    .search-overlay.active .close {
      @apply opacity-100 {} }
  .search-overlay .close {
    z-index: 2;
    bottom: 100%;
    width: 2.08333rem;
    height: 2.08333rem;
    @apply flex justify-center pointer-events-none absolute items-center opacity-0 transition-all duration-1000 {} }
    .search-overlay .close i {
      font-size: 1.5625rem;
      @apply text-grey-333 justify-center {} }
  .search-overlay .searchbox {
    transform: translateY(100%);
    @apply flex  transition-all relative duration-1000 opacity-0 w-full {} }
    .search-overlay .searchbox input {
      @apply border-b border-dark text-grey-333  rounded-none placeholder:text-16 placeholder:text-grey-333 font-light p-0 text-24 {}      height: 40px;
      background: transparent; }

@screen lg {
  .search-overlay .searchbox input {
    width: 600px; } }
    .search-overlay .searchbox button {
      @apply pl-4 h-full transition-all {} }
      .search-overlay .searchbox button em {
        @apply text-grey-333 font-light border-none rounded-sm flex items-center justify-center rounded-sm h-full transition-all {}        font-size: 32px; }
  .search-overlay .block-title {
    @apply text-md {} }

.contact-us .block-title {
  @apply text-left {} }

.contact-us .block-wrap:not(:last-child) {
  margin-bottom: 1.5625rem; }

@screen lg {
  .contact-us .block-wrap:not(:last-child) {
    margin-bottom: 3.125rem; } }

.contact-us .block-wrap.first .desc li {
  @apply mb-3 {} }

@screen lg {
  .contact-us .block-wrap.first .desc li {
    @apply mb-4 {} } }

.contact-us .desc {
  @apply leading-normal {} }
  .contact-us .desc li:before {
    content: '-';
    display: inline-block;
    pointer-events: none;
    left: 0;
    bottom: 0;
    z-index: 9;
    height: 6px;
    width: 6px; }

.contact-us .wrap-left {
  @apply mt-6 {} }

.download-document .top-nav-wrap {
  margin-bottom: 2.08333rem; }
  @media screen and (max-width: 768px) {
    .download-document .top-nav-wrap {
      @apply overflow-x-auto {} }
      .download-document .top-nav-wrap ul {
        @apply start-item whitespace-nowrap pb-1 {}        justify-content: flex-start; }
        .download-document .top-nav-wrap ul li:first-child {
          @apply pl-0 {} } }

@media screen and (max-width: 1024px) {
  .download-document .table-wrap table {
    @apply whitespace-normal {} } }

.download-document .table-wrap table td:first-child, .download-document .table-wrap table td:last-child, .download-document .table-wrap table th:first-child, .download-document .table-wrap table th:last-child {
  width: 16%; }

.download-document .table-wrap table td:last-child, .download-document .table-wrap table th:last-child {
  @apply text-center {} }

.download-document .table-wrap table tbody td {
  @apply bg-white relative {} }

.download-document .table-wrap table tbody .thead {
  counter-increment: numb;
  counter-reset: section; }
  .download-document .table-wrap table tbody .thead th {
    @apply text-blue-0A2ECC {} }

.download-document .table-wrap table tbody .tnormal td {
  @apply border-b border-b-grey-f5 {} }

.download-document .table-wrap table tbody th {
  @apply relative  bg-grey-f5 border-r border-r-grey-cc {}  padding: 0.83333rem 1.5625rem;
  font-size: 0.83333rem; }
  @media screen and (max-width: 576px) {
    .download-document .table-wrap table tbody th {
      padding: 0.625rem 1.04167rem;
      font-size: 0.9375rem; }
      .download-document .table-wrap table tbody th * {
        font-size: 0.9375rem; } }
  .download-document .table-wrap table tbody th:first-child {
    @apply text-blue-0A2ECC font-bold leading-none {}    font-size: 16px; }

.about-management .headline {
  @apply mt-4 {} }

.about-management .block-wrap:not(:last-child) {
  margin-bottom: 2.08333rem; }

@screen lg {
  .about-management .block-wrap:not(:last-child) {
    margin-bottom: 4.16667rem; } }

.about-management .table-wrap {
  @apply mt-4 {} }

@screen lg {
  .about-management .table-wrap thead th {
    padding: 0.83333rem 3.125rem; } }
  .about-management .table-wrap thead th:nth-child(2) {
    @apply text-center {} }
  .about-management .table-wrap thead th:last-child {
    @apply text-right {} }

@screen lg {
  .about-management .table-wrap tbody td {
    padding: 0.83333rem 3.125rem; } }
  .about-management .table-wrap tbody td:nth-child(2) {
    @apply text-center {} }
  .about-management .table-wrap tbody td:last-child {
    @apply text-right {} }

.about-conduct-csr .desc {
  @apply text-center {}  font-size: 1.45833rem; }
  .about-conduct-csr .desc * {
    font-size: 1.45833rem; }
  .about-conduct-csr .desc strong {
    @apply font-bold text-blue-0A2ECC {} }

@screen lg {
  .about-conduct-csr .desc {
    font-size: 1.875rem; }
    .about-conduct-csr .desc * {
      font-size: 1.875rem; } }

.about-conduct-csr .top-nav-wrap {
  @apply mb-3 {} }

@screen md {
  .about-conduct-csr .top-nav-wrap {
    @apply mb-7 {} } }
  @media screen and (max-width: 768px) {
    .about-conduct-csr .top-nav-wrap {
      @apply overflow-x-auto {} }
      .about-conduct-csr .top-nav-wrap ul {
        @apply start-item whitespace-nowrap pb-1 {}        justify-content: flex-start; }
        .about-conduct-csr .top-nav-wrap ul li:first-child {
          @apply pl-0 {} } }

.about-conduct-csr .img-wrap a {
  @apply center-item {} }

.about-conduct-csr .headline {
  @apply mt-4 {} }

.about-conduct-csr .block-wrap {
  @apply border-b border-b-grey-cc {} }

.about-conduct-csr .block-item-wrap .item {
  @apply start-item {}  align-items: center; }
  @media screen and (max-width: 576px) {
    .about-conduct-csr .block-item-wrap .item {
      @apply col-ver text-center {} } }

.about-conduct-csr .block-item-wrap .icon {
  display: flex;
  height: 50px;
  width: auto;
  min-width: 50px; }

@screen sm {
  .about-conduct-csr .block-item-wrap .icon {
    margin-right: 1.5625rem; } }
  @media screen and (max-width: 576px) {
    .about-conduct-csr .block-item-wrap .icon {
      @apply mb-3 {} } }
  .about-conduct-csr .block-item-wrap .icon img {
    width: 100%;
    height: 100%;
    object-fit: contain; }

.about-conduct-csr .block-item-wrap .desc {
  @apply text-grey-151 leading-normal text-left {}  font-size: 1.04167rem; }
  .about-conduct-csr .block-item-wrap .desc * {
    @apply text-grey-151 leading-normal {}    font-size: 1.04167rem; }

.about-conduct-csr .block-item-wrap .row {
  @apply justify-start {} }

@screen lg {
  .about-conduct-csr .block-item-wrap .row {
    margin: 0 -40px -70px -40px; }
    .about-conduct-csr .block-item-wrap .row .col {
      padding: 0 40px;
      margin-bottom: 70px; } }

.about-overview .fullcontent p span, .about-overview .fullcontent p strong {
  @apply text-blue-0A2ECC {} }

.about-shareholder .block-wrap {
  margin-bottom: 1.5625rem; }

@screen lg {
  .about-shareholder .block-wrap {
    margin-bottom: 2.08333rem; } }

.about-story .block-wrap {
  @apply relative {} }

.about-story time {
  @apply bg-grey-f5 center-item leading-none text-blue-0A2ECC font-bold w-fit mb-4 {}  border-radius: 15px;
  padding: 0.41667rem 1.04167rem; }

@screen lg {
  .about-story time {
    @apply mb-5 {} } }

.about-story .desc {
  @apply mb-4 {} }

@screen lg {
  .about-story .desc {
    @apply mb-5 {} } }

.about-story .row {
  @apply relative {} }
  @media screen and (max-width: 1024px) {
    .about-story .row {
      @apply mb-0 pb-4 {} }
      .about-story .row:not(:last-child)::after {
        content: '';
        @apply block absolute pointer-events-none top-[10px] z-10  transition h-full bg-blue-0A2ECC {}        width: 1px;
        left: 27px; }
      .about-story .row .col {
        @apply mb-0 {} } }

@screen lg {
  .about-story .row {
    margin: 0 -15px -60px; }
    .about-story .row .col {
      margin-bottom: 60px; } }

@screen lg {
  .about-story .item:last-child {
    margin-bottom: 50px; } }

@media screen and (max-width: 1024px) {
  .about-story .item {
    padding-left: 40px; } }

.about-story .row:not(:last-child) .lg\:w-2\/12 {
  @apply relative {} }
  .about-story .row:not(:last-child) .lg\:w-2\/12::after {
    content: '';
    @apply block absolute pointer-events-none top-[10px] z-10  transition h-full bg-blue-0A2ECC {}    width: 1px;
    right: 35px; }
    @media screen and (max-width: 1024px) {
      .about-story .row:not(:last-child) .lg\:w-2\/12::after {
        @apply hidden {} } }

.about-story .years {
  @apply text-blue-0A2ECC font-normal leading-none relative w-full z-40 {}  font-size: 2.08333rem; }
  @media screen and (max-width: 1024px) {
    .about-story .years {
      padding-left: 40px;
      @apply mb-5 {} } }

@screen lg {
  .about-story .years {
    font-size: 2.91667rem; } }
  .about-story .years::after {
    @apply absolute-y {}    content: '';
    background-repeat: no-repeat;
    background-position: center !important;
    appearance: none !important;
    box-shadow: none !important;
    color: transparent;
    width: 24px;
    height: 24px;
    min-width: 24px;
    line-height: 24px;
    display: inline-block;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='22' cy='22' r='22' fill='%23ffffff'/><circle cx='22' cy='22' r='19.5' stroke='%230A2ECC'/><circle cx='22' cy='22' r='12' fill='%230A2ECC'/></svg>"); }

@screen lg {
  .about-story .years::after {
    width: 40px;
    height: 40px;
    min-width: 40px;
    line-height: 40px;
    right: 0; } }
    @media screen and (max-width: 1024px) {
      .about-story .years::after {
        left: 0;
        background-image: url("data:image/svg+xml;charset=utf-8,<svg width='24' height='24' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='22' cy='22' r='22' fill='%23ffffff'/><circle cx='22' cy='22' r='19.5' stroke='%230A2ECC'/><circle cx='22' cy='22' r='12' fill='%230A2ECC'/></svg>"); } }

.global-breadcrumb {
  @apply flex justify-start items-center py-2 {}  min-height: 50px;
  box-shadow: 5px 5px 25px 0px #0000000F; }
  .global-breadcrumb ol {
    @apply p-0 list-none {} }
    .global-breadcrumb ol li {
      @apply inline-flex relative leading-none {}      padding: 0 14px; }
      .global-breadcrumb ol li:after {
        content: '\f054';
        font-family: 'Font Awesome 6 Pro';
        @apply text-grey-666 block font-light absolute-y pointer-events-none z-10 left-full {}        font-size: 16px;
        height: 16px;
        width: 12px; }
        @media screen and (max-width: 576px) {
          .global-breadcrumb ol li:after {
            font-size: 14px; } }
      .global-breadcrumb ol li:first-child {
        @apply pl-0 {} }
        .global-breadcrumb ol li:first-child span, .global-breadcrumb ol li:first-child a {
          font-size: 0 !important; }
        .global-breadcrumb ol li:first-child:before {
          content: '\f015';
          font-family: 'Font Awesome 6 Pro';
          @apply text-grey-666 block pointer-events-none z-10 font-light {}          font-size: 16px;
          height: 16px;
          width: 16px; }
          @media screen and (max-width: 576px) {
            .global-breadcrumb ol li:first-child:before {
              font-size: 14px;
              height: 14px;
              width: 14px; } }
      .global-breadcrumb ol li:last-child:after {
        @apply hidden {} }
      .global-breadcrumb ol li:last-child a {
        @apply text-grey-666 {} }
      .global-breadcrumb ol li a, .global-breadcrumb ol li span {
        font-size: 16px;
        @apply text-grey-666 font-normal {} }
        @media screen and (max-width: 576px) {
          .global-breadcrumb ol li a, .global-breadcrumb ol li span {
            font-size: 14px; } }

.modulepager {
  @apply flex items-center justify-center pt-8 pb-0 {}  padding-bottom: 30px; }
  .modulepager ul {
    padding: 0;
    margin: 0;
    position: relative; }
    .modulepager ul li {
      display: inline-block;
      padding: 0 10px; }
      .modulepager ul li.active a, .modulepager ul li.active span {
        @apply bg-blue-0A2ECC text-white {} }
      .modulepager ul li:hover a, .modulepager ul li:hover span {
        @apply underline {} }
      .modulepager ul li span, .modulepager ul li a {
        width: 40px;
        height: 40px;
        @apply center-item rounded-full font-bold text-grey-666 bg-grey-f5 {}        font-size: 20px; }
        @media screen and (max-width: 1024px) {
          .modulepager ul li span, .modulepager ul li a {
            height: 32px;
            width: 32px; } }

.top-banner .img-wrap {
  @apply relative {} }
  @media screen and (max-width: 1024px) {
    .top-banner .img-wrap {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 56.25%; }
      .top-banner .img-wrap img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; } }

@screen lg {
  .top-banner .img-wrap {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 22.08333%; }
    .top-banner .img-wrap img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; } }

@media screen and (max-width: 576px) {
  .top-banner .txt-wrap {
    @apply py-6  text-center justify-center {} } }

@screen sm {
  .top-banner .txt-wrap {
    @apply absolute-x z-30 {}    bottom: 2.08333rem; } }

@screen lg {
  .top-banner .txt-wrap {
    bottom: 3.33333rem; } }

.top-banner .txt-wrap .title {
  @apply font-bold font-sharp leading-none text-grey-333 {}  font-size: 2.39583rem; }

@screen sm {
  .top-banner .txt-wrap .title {
    @apply text-white {} } }
  .top-banner .txt-wrap .title * {
    font-size: 2.39583rem; }

@screen lg {
  .top-banner .txt-wrap .title {
    font-size: 3.33333rem; }
    .top-banner .txt-wrap .title * {
      font-size: 3.33333rem; } }
  .top-banner .txt-wrap .title span {
    @apply text-blue-006BEA {} }

.timeline-social-wrap {
  @apply center-item relative  border-b border-b-grey-cc border-t-2 border-t-grey-333 {}  padding: 0.78125rem 0; }
  .timeline-social-wrap time {
    @apply text-grey-666 font-normal {} }
  .timeline-social-wrap .fa-share {
    @apply absolute-y right-0 z-10 {} }
    .timeline-social-wrap .fa-share em {
      font-size: 24px;
      color: #1877F2; }

.career-detail .title-wrap {
  @apply gap-item {} }
  @media screen and (max-width: 576px) {
    .career-detail .title-wrap {
      @apply col-ver pb-6 {} } }

.career-detail .block-title {
  @apply mb-3 text-left {} }
  @media screen and (max-width: 576px) {
    .career-detail .block-title {
      @apply mb-5 {} } }

@screen lg {
  .career-detail .table-detail-wrap {
    max-width: 50%; } }

@media screen and (max-width: 576px) {
  .career-detail .table-detail-wrap tr {
    @apply col-ver {} } }

.career-detail .table-detail-wrap td {
  @apply w-full {}  font-size: 16px;
  padding: 5px 0;
  @apply leading-normal {} }

@screen lg {
  .career-detail .table-detail-wrap td {
    @apply w-1/2 {} } }
  .career-detail .table-detail-wrap td * {
    font-size: 16px;
    @apply leading-normal {} }
  .career-detail .table-detail-wrap td time {
    font-size: 16px;
    @apply text-blue-0A2ECC {} }
  .career-detail .table-detail-wrap td em {
    @apply leading-none inline-block mr-4 {}    @apply mr-6 {} }

.other-recruitment {
  padding-bottom: 3.125rem; }
  .other-recruitment .block-title {
    @apply text-left {} }
  .other-recruitment time {
    @apply text-grey-666 mt-2 {}    font-size: 0.83333rem; }

.form-apply iframe {
  width: 100%;
  height: 410px; }

@screen xl {
  .form-apply iframe {
    @apply w-full {} } }

#ctl00_phMain_jobApply_upContact {
  margin-bottom: 0 !important; }

.apply-frm {
  margin: 0 !important;
  overflow: hidden;
  background: #fff !important;
  width: 100%;
  height: 100%; }
  @media screen and (max-width: 576px) {
    .apply-frm {
      overflow: hidden;
      width: 100%; } }
  .apply-frm .recruitment-container {
    min-width: 1110px !important; }
  .apply-frm .frm-captcha {
    @apply my-6 {} }

@screen lg {
  .apply-frm .frm-captcha {
    @apply absolute right-0  pr-8 {}    bottom: 40px;
    width: auto !important;
    margin-top: 0 !important; } }
  .apply-frm .wrap-form {
    @apply relative {}    padding: 1.5625rem; }

@screen lg {
  .apply-frm .wrap-form {
    height: auto;
    padding: 1.5625rem; } }
  .apply-frm h4 {
    @apply hidden {} }
  .apply-frm input[type="file"] {
    left: 0; }
  .apply-frm > div {
    width: 100%; }
  .apply-frm .RadUpload {
    width: 320px;
    pointer-events: auto; }
    .apply-frm .RadUpload .ruButton {
      @apply text-16 uppercase opacity-0 {}      font-family: 'Nunito Sans', sans-serif; }
    .apply-frm .RadUpload .ruInputs {
      width: 320px;
      position: relative; }
    .apply-frm .RadUpload .ruRemove {
      position: absolute;
      bottom: -44px;
      left: 360px;
      opacity: 1;
      font-size: 12px; }
    .apply-frm .RadUpload .ruBrowse {
      @apply hidden {} }
  .apply-frm #ctl00_phMain_jobApply_pnlNewComment {
    position: relative;
    display: flex;
    flex-wrap: wrap; }
    .apply-frm #ctl00_phMain_jobApply_pnlNewComment > .form-group {
      width: 100%;
      margin-top: -10px;
      position: relative; }
    .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-left, .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-right {
      flex: 0 0 100%;
      max-width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-left > .form-group, .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-right > .form-group {
        margin-bottom: 30px; }
      @media (min-width: 576px) {
        .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-left, .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-right {
          margin: 0 -10px; }
          .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-left > .form-group, .apply-frm #ctl00_phMain_jobApply_pnlNewComment .col-right > .form-group {
            padding: 0 10px; } }
  .apply-frm .col-left > .form-group {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 576px) {
      .apply-frm .col-left > .form-group:nth-child(1), .apply-frm .col-left > .form-group:nth-child(2), .apply-frm .col-left > .form-group:nth-child(3), .apply-frm .col-left > .form-group:nth-child(4) {
        flex: 0 0 50%;
        max-width: 50%; } }
  .apply-frm .col-right > .form-group {
    flex: 0 0 100%;
    max-width: 100%; }
  .apply-frm .fa-exclamation-triangle {
    @apply absolute font-normal {}    bottom: -22px;
    left: 20px;
    color: #f30000;
    font-size: 12px; }
  .apply-frm .label {
    display: none;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    margin-bottom: 4px; }
    .apply-frm .label .required {
      color: red; }
  .apply-frm textarea {
    height: 90px; }

@screen xl {
  .apply-frm textarea {
    height: 130px; } }
  .apply-frm .RadAsyncUpload .ruDropZone {
    display: none; }
  .apply-frm .RadAsyncUpload .ruFileWrap {
    position: static;
    display: block;
    height: auto !important;
    max-height: 50px; }
    .apply-frm .RadAsyncUpload .ruFileWrap .ruUploadProgress {
      position: absolute;
      bottom: -18px;
      left: 360px; }
  .apply-frm .RadAsyncUpload .ruFileInput, .apply-frm .RadAsyncUpload .rugreyse {
    height: 50px;
    width: 120px;
    height: 50px;
    text-transform: uppercase;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FF0000;
    background: #FF0000;
    height: 50px;
    width: 250px; }
    .apply-frm .RadAsyncUpload .ruFileInput:hover, .apply-frm .RadAsyncUpload .rugreyse:hover {
      background: white !important;
      color: #FF0000 !important; }
  .apply-frm .RadAsyncUpload .ruFileInput {
    cursor: pointer;
    border: 1px solid #FF0000;
    pointer-events: auto;
    top: 0 !important;
    left: 0;
    padding: 0 !important;
    bottom: 10px;
    position: absolute;
    opacity: 0;
    height: 40px;
    width: 320px !important;
    cursor: pointer; }
    .apply-frm .RadAsyncUpload .ruFileInput:hover {
      background: white !important;
      color: #FF0000 !important; }
  @media screen and (max-width: 1024px) {
    .apply-frm .form-group-btn {
      @apply start-item mt-6 {} } }

@screen lg {
  .apply-frm .form-group-btn {
    @apply absolute right-[20px] bottom-0 {} } }
  .apply-frm .frm-btnwrap {
    @apply start-item w-auto mt-0 {} }
    @media screen and (max-width: 576px) {
      .apply-frm .frm-btnwrap {
        position: static !important; } }
  .apply-frm .attachfile1 {
    @apply block relative {} }
    .apply-frm .attachfile1 .left {
      @apply absolute top-0 left-0 bottom-0 right-0 w-full h-full  cursor-pointer pointer-events-none {}      z-index: 99;
      max-width: 180px; }
    .apply-frm .attachfile1 label {
      @apply center-item text-16  font-normal text-grey-333 uppercase relative pointer-events-none text-white bg-grey-f5 {}      margin-top: 0;
      background: #F5F5F5;
      width: 320px;
      height: 40px;
      color: #333 !important;
      font-weight: 400 !important; }
      @media screen and (max-width: 1024px) {
        .apply-frm .attachfile1 label {
          @apply w-full px-3 {}          word-break: break-normal;
          font-size: 0.83333rem; }
          .apply-frm .attachfile1 label * {
            font-size: 0.83333rem; } }
      .apply-frm .attachfile1 label * {
        color: #333 !important;
        font-weight: 400 !important; }
      .apply-frm .attachfile1 label input[type='file'] {
        @apply w-full cursor-pointer {} }
      .apply-frm .attachfile1 label input[type='file']::file-selector-button {
        cursor: pointer; }
      .apply-frm .attachfile1 label input[type='file']::-webkit-file-upload-button {
        cursor: pointer; }
      .apply-frm .attachfile1 label ::-webkit-file-upload-button {
        cursor: pointer; }
  .apply-frm .attachfile2 {
    display: none; }
  .apply-frm .form-group {
    position: relative; }
    .apply-frm .form-group.disable-input input {
      @apply pointer-events-none {}      background: #F1F1F1;
      border: none; }

.careers-list .sub-title {
  @apply font-bold leading-none text-blue-0A2ECC my-4 {}  font-size: 0.9375rem; }

.careers-list .filter-btn-wrap {
  @apply transition mt-8 {} }
  .careers-list .filter-btn-wrap.disble-btn {
    @apply opacity-0 pointer-events-none {} }

.careers-list .filter-table-wrap {
  @apply border-t-2 border-dark mt-4 {} }

@screen lg {
  .careers-list .filter-table-wrap {
    @apply mt-5 {} } }
  @media screen and (max-width: 768px) {
    .careers-list .filter-table-wrap {
      @apply overflow-x-auto {} }
      .careers-list .filter-table-wrap table {
        @apply whitespace-nowrap {} } }
  .careers-list .filter-table-wrap thead th {
    @apply text-grey-333 font-bold  border-b border-grey-cc {}    padding: 13px 20px; }
    .careers-list .filter-table-wrap thead th:first-child {
      @apply pl-0 {} }
  .careers-list .filter-table-wrap tbody tr {
    @apply hidden {} }
    .careers-list .filter-table-wrap tbody tr td {
      @apply border-b border-grey-cc text-grey-333 font-normal {}      padding: 13px 20px; }
      .careers-list .filter-table-wrap tbody tr td:first-child {
        @apply pl-0 {} }
      .careers-list .filter-table-wrap tbody tr td * {
        @apply text-grey-333 font-normal {} }
      .careers-list .filter-table-wrap tbody tr td a:hover {
        @apply underline {} }
      .careers-list .filter-table-wrap tbody tr td em {
        font-size: 24px;
        @apply leading-none font-light {} }
        @media screen and (max-width: 1024px) {
          .careers-list .filter-table-wrap tbody tr td em {
            font-size: 20px; } }
        @media screen and (max-width: 768px) {
          .careers-list .filter-table-wrap tbody tr td em {
            font-size: 16px; } }

.careers-benefit {
  @apply relative {} }
  .careers-benefit .block-wrap {
    @apply relative {} }
    @media screen and (max-width: 1024px) {
      .careers-benefit .block-wrap {
        @apply col-ver {} } }
  .careers-benefit .container-fluid {
    @apply p-0 bg-blue-E7F1F8 {} }
    @media screen and (max-width: 1024px) {
      .careers-benefit .container-fluid {
        @apply order-2 {} } }

@screen lg {
  .careers-benefit .container-fluid {
    @apply absolute-center z-30 {} } }
  .careers-benefit .txt-wrap {
    padding: 2.08333rem 0 0 0; }

@screen lg {
  .careers-benefit .txt-wrap {
    padding: 3.64583rem 0; } }
  .careers-benefit .container {
    @apply relative z-30 {} }

.careers-contact-information .headline {
  @apply mb-4 {} }

.careers-contact-information .desc {
  @apply mb-1 {} }
  .careers-contact-information .desc * {
    @apply mb-2 {} }
  .careers-contact-information .desc a {
    @apply text-blue-006BEA {} }

.claim-notice-detail .zone-desc {
  @apply text-center {} }
  .claim-notice-detail .zone-desc img {
    @apply block mx-auto {} }

.claim-notice-detail .block-wrap {
  padding-top: 1.5625rem; }

@screen lg {
  .claim-notice-detail .block-wrap {
    padding-top: 2.60417rem; } }

.claim-notice-detail .title-wrap {
  @apply pb-3 {} }

@screen lg {
  .claim-notice-detail .title-wrap {
    @apply pb-4 {} } }

.claim-notice-detail .headline span {
  @apply font-normal {}  font-size: 1.04167rem; }

.claim-notice-detail .txt-wrap {
  border-bottom: 1px solid #CCCCCC;
  padding: 20px 0; }
  .claim-notice-detail .txt-wrap em {
    @apply text-grey-333 leading-none inline-block mr-3 {}    font-size: 18px; }

@screen lg {
  .claim-notice-detail .txt-wrap em {
    font-size: 20px;
    @apply mr-4 {} } }
  .claim-notice-detail .txt-wrap p {
    @apply text-grey-333 font-normal {}    font-size: 0.83333rem; }
    .claim-notice-detail .txt-wrap p * {
      font-size: 0.83333rem; }
  .claim-notice-detail .txt-wrap li {
    @apply start-item {} }
    .claim-notice-detail .txt-wrap li:not(:last-child) {
      @apply mb-3 {} }
  .claim-notice-detail .txt-wrap h3, .claim-notice-detail .txt-wrap h4 {
    @apply my-3 block font-bold text-grey-333 {}    font-size: 1.04167rem; }
    .claim-notice-detail .txt-wrap h3 *, .claim-notice-detail .txt-wrap h4 * {
      @apply block font-bold text-grey-333 {}      font-size: 1.04167rem; }

@screen lg {
  .claim-notice-detail .txt-wrap h3, .claim-notice-detail .txt-wrap h4 {
    padding-left: 35px; } }

.claim-information {
  background: rgba(231, 241, 248, 0.8); }
  .claim-information .desc {
    @apply text-center {} }

.claim-type-information .row {
  margin: 0 -15px; }
  .claim-type-information .row:not(:last-child) {
    margin-bottom: 3.125rem; }
  .claim-type-information .row .col {
    padding: 0 15px !important;
    margin-bottom: 0 !important; }
  .claim-type-information .row:nth-child(even) {
    @apply flex-row-reverse {} }

@screen lg {
  .claim-type-information .row:nth-child(even) .txt-wrap {
    @apply pr-8 {} } }

@screen lg {
  .claim-type-information .row:nth-child(odd) .txt-wrap {
    @apply pl-8 {} } }

.claim-type-information .txt-wrap {
  @apply col-hor h-full {} }
  @media screen and (max-width: 1024px) {
    .claim-type-information .txt-wrap {
      @apply pt-6 {} } }

.claim-type-information .wrap-left {
  @apply mt-4 {} }

@screen lg {
  .claim-type-information .wrap-left {
    @apply mt-6 {} } }

.claim-type-information .img-wrap a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%; }
  .claim-type-information .img-wrap a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }

.claim-type-information .desc {
  @apply leading-normal {} }

.credit-rating-list .block-wrap {
  @apply border-t-2 border-dark {}  padding-top: 20px;
  margin-top: 1.04167rem; }

@screen sm {
  .credit-rating-list .block-wrap {
    paddingp-top: 30px 0;
    margin-top: 1.5625rem; } }

@screen lg {
  .credit-rating-list .block-wrap {
    margin-top: 3.125rem; } }
  .credit-rating-list .block-wrap h3 {
    @apply text-blue-0A2ECC font-bold my-4 {} }
  .credit-rating-list .block-wrap.achievement-wrapper .table-wrap thead th {
    width: auto; }
    .credit-rating-list .block-wrap.achievement-wrapper .table-wrap thead th:first-child {
      width: 16%; }
  .credit-rating-list .block-wrap.achievement-wrapper .table-wrap tbody td {
    width: auto; }
    .credit-rating-list .block-wrap.achievement-wrapper .table-wrap tbody td:first-child {
      width: 16%; }

@media screen and (max-width: 576px) {
  .credit-rating-list .top-nav-wrap {
    @apply overflow-x-auto {} }
    .credit-rating-list .top-nav-wrap ul {
      @apply start-item whitespace-nowrap pb-1 {}      justify-content: flex-start; }
      .credit-rating-list .top-nav-wrap ul li:first-child {
        @apply pl-0 {} } }

.credit-rating-list .top-nav-wrap h3, .credit-rating-list .top-nav-wrap h4 {
  @apply text-blue-0A2ECC font-bold {}  font-size: 0.9375rem; }

.credit-rating-list .table-wrap thead th {
  width: 16%; }
  .credit-rating-list .table-wrap thead th:last-child {
    @apply w-auto {} }

.credit-rating-list .table-wrap tbody td {
  width: 16%; }
  .credit-rating-list .table-wrap tbody td:last-child {
    @apply w-auto {} }

.home-about-us .wrap-center {
  margin-top: 1.5625rem; }

@screen lg {
  .home-about-us .wrap-center {
    margin-top: 2.08333rem; } }

.primary-banner {
  @apply relative z-10 overflow-hidden {} }
  .primary-banner .banner-container {
    @apply relative {} }
    @media screen and (max-width: 1024px) {
      .primary-banner .banner-container {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 101.89873%; }
        .primary-banner .banner-container .swiper {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0; } }
  .primary-banner .swiper .image-src {
    @apply relative {} }
    .primary-banner .swiper .image-src:hover::after {
      @apply opacity-20 {} }
    .primary-banner .swiper .image-src::after {
      content: '';
      opacity: 0.35;
      @apply block absolute pointer-events-none left-0 bottom-0 z-10  transition w-full h-full {}      background: linear-gradient(37.1deg, #000000 32.43%, rgba(0, 0, 0, 0.38) 59.95%); }

@screen lg {
  .primary-banner .swiper .image-src a {
    display: block;
    height: calc( 100vh - 100px);
    width: auto; }
    .primary-banner .swiper .image-src a img, .primary-banner .swiper .image-src a video {
      width: 100%;
      height: 100%;
      object-fit: cover; } }
    @media screen and (max-width: 1024px) {
      .primary-banner .swiper .image-src a {
        position: relative;
        display: block;
        height: 0;
        overflow: hidden;
        padding-top: 101.89873%; }
        .primary-banner .swiper .image-src a img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          transition: .3s ease-in-out all; } }
  .primary-banner .swiper-pagination {
    @apply absolute z-50 center-item  w-auto {}    left: 50% !important;
    transform: translateX(-50%);
    bottom: 3.125rem !important; }
    @media screen and (max-width: 1024px) {
      .primary-banner .swiper-pagination {
        bottom: 1.5625rem !important; } }
    @media screen and (max-width: 768px) {
      .primary-banner .swiper-pagination {
        bottom: 1.04167rem; } }
    @media screen and (max-width: 576px) {
      .primary-banner .swiper-pagination {
        bottom: 0.78125rem; } }
    .primary-banner .swiper-pagination .swiper-pagination-bullet {
      background: white;
      border-radius: 0 !important;
      opacity: .5;
      width: 1.04167rem;
      height: 2px; }
      .primary-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        opacity: 1 !important;
        width: 3.125rem; }
  .primary-banner .title, .primary-banner .desc {
    @apply font-normal text-center leading-tight {} }
  .primary-banner .title {
    color: #4fc6df;
    font-size: rr(26px); }

@screen lg {
  .primary-banner .title {
    font-size: 1.66667rem; } }
  .primary-banner .desc {
    color: #0e3cdc;
    font-size: 1.66667rem; }

@screen sm {
  .primary-banner .desc {
    font-size: 2.08333rem; } }

@screen lg {
  .primary-banner .desc {
    font-size: 2.91667rem; } }
  .primary-banner .txt-wrap {
    @apply absolute-x w-full px-8 z-50 {}    bottom: 4.6875rem; }
    @media screen and (max-width: 1024px) {
      .primary-banner .txt-wrap {
        bottom: 3.125rem; } }
    @media screen and (max-width: 576px) {
      .primary-banner .txt-wrap {
        bottom: 2.08333rem; } }

.home-claim {
  @apply bg-blue-E7F1F8 {} }
  .home-claim .row {
    @apply justify-between {} }
  .home-claim .list {
    margin-top: 2.08333rem; }

@screen lg {
  .home-claim .list {
    margin-top: 3.125rem; } }
    .home-claim .list ul {
      @apply col-hor {} }
    .home-claim .list li {
      @apply w-full {} }
      .home-claim .list li:first-child a {
        @apply border-t-2  border-grey-111 {} }
      .home-claim .list li:last-child a {
        @apply border-b border-grey-cc {} }
      .home-claim .list li:hover a {
        @apply border-t-blue-0A2ECC {} }
        .home-claim .list li:hover a em {
          @apply opacity-100 {} }
      .home-claim .list li a {
        @apply text-grey-333 font-normal gap-item border-t border-t-grey-cc {}        transition: .4s all  ease-in-out;
        font-size: 1.25rem;
        padding: 0.625rem 0; }

@screen lg {
  .home-claim .list li a {
    padding: 1.04167rem 0;
    font-size: 1.66667rem; } }
        .home-claim .list li a * {
          @apply text-grey-333 font-normal {}          font-size: 1.25rem; }
        .home-claim .list li a em {
          font-size: 24px;
          transition: .4s all  ease-in-out;
          @apply leading-none text-grey-333 opacity-0 pointer-events-none {} }
  .home-claim .img-wrap a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 100%; }
    .home-claim .img-wrap a img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      transition: .3s ease-in-out all; }

.home-highlight-news .top-row .headline {
  padding-top: 1.04167rem;
  margin-left: 4.16667rem; }

.home-highlight-news .trinity-swiper {
  padding-top: 2.08333rem; }

@screen lg {
  .home-highlight-news .trinity-swiper {
    padding-top: 3.125rem; } }

.home-highlight-news .sub-title {
  margin-bottom: 1.5625rem; }

.home-rating .block-wrap {
  @apply bg-blue-003675 {} }

.home-rating .row {
  padding: 4.42708rem 16% 4.42708rem 8%;
  @apply justify-between {}  @apply mb-0 {} }
  .home-rating .row .col {
    @apply mb-0 {} }
    @media screen and (max-width: 768px) {
      .home-rating .row .col:first-child {
        order: 2; } }

.home-rating .icon {
  @apply center-item my-5 {} }

@screen lg {
  .home-rating .icon {
    margin: 1.19792rem 0 1.82292rem; } }

.home-rating .wrap-item {
  @apply flex flex-wrap h-full {}  align-items: s; }

.home-rating .item {
  @apply w-full {} }
  @media screen and (max-width: 576px) {
    .home-rating .item {
      @apply mt-7 {} } }

@screen sm {
  .home-rating .item {
    @apply w-1/2 {} } }

@screen md {
  .home-rating .item:nth-child(n+3) {
    margin-top: 2.60417rem; } }

.home-rating .sub-line {
  @apply text-white font-normal {}  margin-bottom: 1.04167rem;
  font-size: 16px; }

.home-rating .headline {
  padding-left: 4.16667rem;
  font-size: 1.66667rem; }

.home-rating .wrap-center {
  margin-top: 1.5625rem; }

@screen lg {
  .home-rating .wrap-center {
    margin-top: 2.08333rem; } }

.home-rating .img-wrap {
  @apply center-item {} }
  @media screen and (max-width: 768px) {
    .home-rating .img-wrap {
      @apply mb-8 {} } }

.product-detail .block-title {
  margin-bottom: 2.08333rem; }

@screen lg {
  .product-detail .block-title {
    margin-bottom: 3.64583rem; } }

.product-detail .zone-title {
  margin-bottom: 30px !important; }

@media screen and (max-width: 1024px) {
  .product-detail .table-wrapper {
    @apply overflow-x-auto {} } }

.product-detail .table-wrapper tbody tr:nth-child(odd) td {
  @apply bg-grey-f5 {} }

.product-detail .table-wrapper tbody tr td {
  font-size: 0.83333rem;
  @apply text-grey-151 font-normal bg-white {}  padding: 1.04167rem 0 1.04167rem 1.04167rem; }

@screen lg {
  .product-detail .table-wrapper tbody tr td {
    padding: 1.82292rem 0 1.82292rem 1.82292rem; } }
  .product-detail .table-wrapper tbody tr td:first-child {
    font-size: 0.9375rem;
    @apply text-blue-0A2ECC font-bold {} }

@screen lg {
  .product-detail .table-wrapper tbody tr td:first-child {
    width: 32%; } }
  .product-detail .table-wrapper tbody tr td p {
    @apply mb-3 {} }
  .product-detail .table-wrapper tbody tr td h2 {
    @apply font-bold my-3 {}    font-size: 24px; }
    .product-detail .table-wrapper tbody tr td h2 * {
      @apply font-bold {}      font-size: 24px; }
  .product-detail .table-wrapper tbody tr td h3 {
    @apply font-bold my-2 {}    font-size: 20px; }
    .product-detail .table-wrapper tbody tr td h3 * {
      @apply font-bold {}      font-size: 20px; }
  .product-detail .table-wrapper tbody tr td h4 {
    @apply font-bold my-2 pl-6 {}    font-size: 16px; }
    .product-detail .table-wrapper tbody tr td h4 * {
      @apply font-bold {}      font-size: 16px; }
  .product-detail .table-wrapper tbody tr td strong {
    @apply font-bold {} }
  .product-detail .table-wrapper tbody tr td a {
    @apply text-blue-0A2ECC {} }
  .product-detail .table-wrapper tbody tr td ul {
    @apply mb-2 {} }
    .product-detail .table-wrapper tbody tr td ul li {
      @apply start-item {} }
      .product-detail .table-wrapper tbody tr td ul li:hover {
        @apply text-blue-0A2ECC {} }
        .product-detail .table-wrapper tbody tr td ul li:hover * {
          @apply text-blue-0A2ECC {} }
      .product-detail .table-wrapper tbody tr td ul li::before {
        content: '';
        @apply inline-block z-10  transition  bg-blue-0A2ECC rounded-full mr-1 mt-2 {}        width: 6px;
        height: 6px;
        min-width: 6px; }

@screen lg {
  .product-detail .table-wrapper tbody tr td ul li::before {
    @apply mr-2 {} } }

.product-detail .table-wrapper .table-wrap thead th:nth-child(n+3) {
  @apply text-center {} }
  .product-detail .table-wrapper .table-wrap thead th:nth-child(n+3) em {
    @apply text-blue-0A2ECC {} }

.product-detail .table-wrapper .table-wrap tbody tr:nth-child(even) td {
  background: #F5F5F5 !important; }

.product-detail .table-wrapper .table-wrap tbody tr td {
  background: white;
  padding: 0.625rem 1.04167rem !important; }

@screen lg {
  .product-detail .table-wrapper .table-wrap tbody tr td {
    padding: 0.625rem 1.5625rem !important; } }
  .product-detail .table-wrapper .table-wrap tbody tr td:first-child {
    @apply text-grey-151 text-center {} }

@screen lg {
  .product-detail .table-wrapper .table-wrap tbody tr td:first-child {
    width: 10% !important; } }
  .product-detail .table-wrapper .table-wrap tbody tr td:nth-child(n+3) {
    @apply text-center {} }
    .product-detail .table-wrapper .table-wrap tbody tr td:nth-child(n+3) em {
      @apply text-blue-0A2ECC {} }

.product-zone-page .module-title {
  @apply hidden {} }

@media screen and (max-width: 1024px) {
  .product-zone .row {
    @apply -mx-2 mb-2 {} }
    .product-zone .row .col {
      @apply px-2 mb-4 {} } }

.product-zone.home-product-zone .item-zone .wrap-left {
  margin-top: 1.5625rem; }

@screen lg {
  .product-zone.home-product-zone .item-zone .wrap-left {
    margin-top: 2.08333rem; } }

.product-zone.home-product-zone .item-zone .desc {
  height: 8.85417rem; }
  @media screen and (max-width: 1024px) {
    .product-zone.home-product-zone .item-zone .desc {
      @apply h-auto {} } }

.item-zone {
  padding: 15px;
  @apply bg-grey-f5 h-full {} }

@screen lg {
  .item-zone {
    padding: 25px;
    @apply flex {} } }
  .item-zone .img-src {
    @apply w-full {} }

@screen lg {
  .item-zone .img-src {
    width: 50%; } }
    .item-zone .img-src a {
      position: relative;
      display: block;
      height: 0;
      overflow: hidden;
      padding-top: 100%; }
      .item-zone .img-src a img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        transition: .3s ease-in-out all; }
  .item-zone .txt {
    padding-top: 1.04167rem; }

@screen lg {
  .item-zone .txt {
    width: 50%;
    padding: 1.5625rem 2.08333rem 0 2.08333rem; } }
  .item-zone .headline {
    margin-bottom: 0.72917rem; }
    .item-zone .headline:hover {
      @apply underline {} }
  .item-zone .desc {
    height: 11.45833rem;
    padding-right: 15px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #A0C4F4 #cccccc; }
    .item-zone .desc::-webkit-scrollbar {
      background: #cccccc;
      width: 3px; }
    .item-zone .desc::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px #cccccc;
      background-color: #cccccc; }
    .item-zone .desc::-webkit-scrollbar-thumb {
      background-color: #A0C4F4; }
    @media screen and (max-width: 1024px) {
      .item-zone .desc {
        @apply h-auto {} } }
    .item-zone .desc ul {
      @apply col-hor {} }
      .item-zone .desc ul a {
        @apply text-grey-333 font-normal transition {}        font-size: 0.83333rem; }
        .item-zone .desc ul a:hover {
          @apply text-blue-006BEA {} }
    .item-zone .desc ol li {
      @apply text-grey-333 font-normal mb-1 transition {}      font-size: 0.83333rem; }
      .item-zone .desc ol li * {
        @apply text-grey-333 font-normal {}        font-size: 0.83333rem; }
      .item-zone .desc ol li a {
        @apply transition {} }
        .item-zone .desc ol li a:hover {
          @apply text-blue-006BEA {} }
      .item-zone .desc ol li::before {
        content: '-';
        @apply inline-block  pointer-events-none z-10  transition text-grey-333 {}        font-size: 0.83333rem;
        height: 2px;
        width: 10px; }
      .item-zone .desc ol li:hover {
        @apply text-blue-006BEA {} }
        .item-zone .desc ol li:hover::before {
          @apply text-blue-006BEA {} }

.other-notice-announcement {
  background: rgba(245, 245, 245, 0.5); }

.financial-report-extraordinary-release .table-wrap thead th {
  @apply w-auto {} }
  .financial-report-extraordinary-release .table-wrap thead th:first-child {
    width: 8%; }
  .financial-report-extraordinary-release .table-wrap thead th:nth-child(2) {
    width: 44%; }
    @media screen and (max-width: 1024px) {
      .financial-report-extraordinary-release .table-wrap thead th:nth-child(2) {
        @apply whitespace-normal {}        min-width: 250px; } }
  .financial-report-extraordinary-release .table-wrap thead th:nth-child(3) {
    width: 32%; }
    @media screen and (max-width: 1024px) {
      .financial-report-extraordinary-release .table-wrap thead th:nth-child(3) {
        @apply whitespace-normal {}        min-width: 250px; } }
  .financial-report-extraordinary-release .table-wrap thead th:last-child {
    width: 16%;
    @apply text-center {} }

.financial-report-extraordinary-release .table-wrap tbody td {
  @apply w-auto {} }
  .financial-report-extraordinary-release .table-wrap tbody td:first-child {
    width: 8%; }
  .financial-report-extraordinary-release .table-wrap tbody td:nth-child(2) {
    width: 44%; }
    @media screen and (max-width: 1024px) {
      .financial-report-extraordinary-release .table-wrap tbody td:nth-child(2) {
        @apply whitespace-normal {}        min-width: 250px; } }
  .financial-report-extraordinary-release .table-wrap tbody td:nth-child(3) {
    width: 32%; }
    @media screen and (max-width: 1024px) {
      .financial-report-extraordinary-release .table-wrap tbody td:nth-child(3) {
        @apply whitespace-normal {}        min-width: 250px; } }
  .financial-report-extraordinary-release .table-wrap tbody td:last-child {
    width: 16%;
    @apply text-center {} }
  .financial-report-extraordinary-release .table-wrap tbody td em {
    font-size: 20px; }

.financial-report .top-nav-wrap {
  @apply mb-3 {} }
  @media screen and (max-width: 1024px) {
    .financial-report .top-nav-wrap {
      @apply overflow-x-auto {} }
      .financial-report .top-nav-wrap ul {
        @apply start-item whitespace-nowrap pb-1 {}        justify-content: flex-start; }
        .financial-report .top-nav-wrap ul li:first-child {
          @apply pl-0 {} } }

.financial-report .block-wrap h3 {
  @apply text-blue-0A2ECC font-bold my-4 {} }

.financial-report .block-content:not(:last-child) {
  @apply mb-5 {} }

@screen lg {
  .financial-report .block-content:not(:last-child) {
    margin-bottom: 2.08333rem; } }

.financial-report .second-block {
  margin-top: 2.08333rem; }

.financial-report .table-wrap thead th {
  width: 16%; }
  .financial-report .table-wrap thead th:first-child {
    width: 8%; }
  .financial-report .table-wrap thead th:nth-child(2) {
    width: 24%; }
  .financial-report .table-wrap thead th:nth-child(3) {
    @apply w-auto {} }
  .financial-report .table-wrap thead th:nth-child(n+4) {
    @apply text-center {} }

.financial-report .table-wrap tbody td {
  width: 16%; }
  .financial-report .table-wrap tbody td:first-child {
    width: 8%; }
  .financial-report .table-wrap tbody td:nth-child(2) {
    width: 24%; }
  .financial-report .table-wrap tbody td:nth-child(3) {
    @apply w-auto {} }
  .financial-report .table-wrap tbody td:nth-child(n+4) {
    @apply text-center {} }

.news-display-page .modulepager {
  padding-top: 0;
  padding-bottom: 3.125rem; }

.notice-announcement {
  padding-bottom: 3.125rem; }
  .notice-announcement .row {
    @apply justify-start {} }
  .notice-announcement .block-title {
    margin-bottom: 2.08333rem; }

.news-item .img-src a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .news-item .img-src a img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: .3s ease-in-out all; }

.news-item .txt {
  margin-top: 1.04167rem; }

.news-item .headline a {
  @apply transition {}  display: block;
  display: -webkit-box;
  font-size: 1.04167rem;
  height: 2.70833rem;
  line-height: 1.3;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; }
  .news-item .headline a:hover {
    @apply text-blue-0A2ECC {} }

.news-item .wrap-left {
  @apply mt-3 {} }
