.contact-us
	.block-title
		@apply text-left
	.block-wrap
		&:not(:last-child)
			margin-bottom: r(30px)
			@screen lg
				margin-bottom: r(60px)
		&.first
			.desc
				li
					@apply mb-3
					@screen lg
						@apply mb-4
	.desc
		@apply leading-normal
		li
			&:before
				content: '-'
				display: inline-block
				pointer-events: none
				left: 0
				bottom: 0
				z-index: 9
				height: 6px
				width:  6px
	.wrap-left
		@apply mt-6