.header-site
	@apply fixed top-0  left-0 w-full h-[60px] bg-white border-b border-grey-e4
	z-index: 121
	@screen lg
		@apply h-[80px]
	.container-fluid
		@apply relative gap-item  h-full
		@screen lg
			padding: 0 r(60px)
	.header-wrap-left
		@apply end-item h-full
		align-items: center
.nav-brand
	.logo-desktop
		@apply hidden
		@screen xl
			@apply block
	.logo-mobile
		@apply block
		@screen xl
			@apply hidden
	a
		display: flex
		height: 40px
		width: auto
		img
			width: 100%
			height: 100%
			object-fit: contain
.header-btn-wrap
	@media screen and ( max-width: 1024px)
		@apply mt-6 hidden
		.Module
			@apply w-full
	ul
		@apply center-item
		li
		@media screen and ( max-width: 1024px)
			@apply -mx-3
			li
				@apply w-1/2 px-3
	a
		@apply center-item
		padding: 0 r(17px)
		@media screen and ( max-width: 1024px)
			height: 36px
			@apply border-grey-333 border rounded-full
		@media screen and ( max-width: 576px)
			padding: 0 r(12px)
		em
			font-size: r(24px)
			@apply leading-none text-grey-333
			@media screen and ( max-width: 1024px)
				font-size: 20px
			@media screen and ( max-width: 576px)
				font-size: 18px

.menu-toggle
	@apply flex
	@screen lg
		@apply hidden
.mobile-menu-wrap
	@apply hidden
	@media screen and ( max-width: 1024px)
		@apply fixed left-0 top-[60px] z-40  h-full bg-white opacity-0 pointer-events-none block
		transform: translateX(-100%)
		transition: .45s cubic-bezier(0.6,0,0.4,1)
		padding: r(20px) r(30px) 0 r(15px)
		align-items: flex-start
		width: 320px
		&.is-open
			@apply  opacity-100 pointer-events-auto 
			transform: none
			z-index: 121


.navbar-primary-menu 
	@apply h-full
	@media screen and ( max-width: 1024px)
		@apply h-auto hidden

	ul
		@apply  h-full
		@screen lg
			@apply end-item
		@media screen and ( max-width: 1024px)
			@apply col-hor
		li
			@apply  center-item
			@screen lg
				@apply h-full
	.nav
		>.nav-link
			@apply relative
			@media screen and ( max-width: 1024px)
				>a
					@apply uppercase
				>.title
					a
						@apply uppercase
				
	.nav-link
		@apply static
		&.current-page
			>a
				@apply text-blue-0A2ECC
				&::after
					@apply w-full
			>.title
				>a
					@apply text-blue-0A2ECC
					&::after
						@apply w-full
		@media screen and ( max-width: 1024px)
			@apply w-full justify-start py-2
		a
			@apply text-grey-151 font-bold text-xs uppercase center-item relative
			@media screen and ( max-width: 1024px)
				@apply normal-case
			
			&::after
				content: ''
				@apply block absolute-x pointer-events-none  bottom-[-1px] z-10  transition bg-blue-0A2ECC w-0
				height: 1px
			@screen lg
				@apply h-full
				padding: 0 r(30px)
				font-size: r(16px)
	.drop-down
		@apply flex-col
		.title
			@apply w-full start-item
			@media screen and ( max-width: 1024px)
				@apply gap-item w-full
			@screen lg
				@apply h-full
			em
				@apply end-item font-bold
				font-size: 20px
				width: 40px
				@screen lg
					@apply hidden
		@screen lg
			&:hover
				>.nav-sub
					@apply opacity-100 pointer-events-auto
	.nav-sub
		@media screen and ( max-width: 1024px)
			@apply w-full items-start pt-2 hidden
		@screen lg
			@apply absolute  left-0 h-auto col-hor w-max bg-white  opacity-0 pointer-events-none 
			transition: .45s all  ease-in-out
			top: calc( 100% + 1px  )
			padding: r(14px) 0
			&::before,&::after
				content: ''
				@apply block absolute pointer-events-none  z-10  transition w-screen h-full bg-white bottom-0
			&::before
				@apply right-full
			&::after
				@apply left-full
		a
			@apply font-normal capitalize transition cursor-pointer
			padding: r(12px) r(30px)
			font-size: r(20px)
			@media screen and ( max-width: 1024px)
				padding: r(8px) r(15px)
				font-size: clamp(15px,r(20px),20px)
			&::after
				@apply hidden
			&:hover
				@apply text-blue-006BEA
		.nav-link
			@apply  w-full start-item
		.nav-sub
			@media screen and ( max-width: 1024px)
				@apply pt-0
			@screen lg
				@apply absolute top-0 left-full z-40 opacity-0 pointer-events-none
				&:before,&::after
					@apply opacity-0
	.nav-link-sub
		@media screen and ( max-width: 1024px)
			@apply justify-start
			>a
				@apply font-semibold 
			>.title
				a
					@apply font-semibold 
			.nav-link-sub
				@apply pl-5 py-0
				>a
					@apply font-normal normal-case
				>.title
					a
						@apply font-normal normal-case
		&.active
			>a
				@apply text-blue-006BEA
			>.title
				>a
					@apply text-blue-006BEA