.block-title
	@apply text-dark font-bold  leading-tight text-lg  text-center font-sharp
	margin-bottom: (20px)
	*
		@apply text-dark font-bold  text-lg font-sharp
	@screen lg
		@apply text-xl
		*
			@apply text-xl
.eyebrow 
	@apply text-dark font-bold  relative  leading-none
	font-size: r(36px)
	*
		@apply text-dark font-bold  
		font-size: r(36px)
	@screen lg
		font-size: r(55px)
		*
			font-size: r(55px)
.headline
	@apply text-grey-333 font-bold  leading-tight text-xs
	*
		@apply text-grey-333 font-bold text-xs
	&.t-16
		font-size: r(16px)
		*
			font-size: r(16px)
	&.t-18
		font-size: r(18px)
		*
			font-size: r(18px)
	&.t-20
		font-size: r(20px)
		*
			font-size: r(20px)
	&.t-32
		@apply text-sm
		*
			@apply text-sm
	&.grey
		@apply text-grey-333
		*
			@apply text-grey-333
	&.white
		@apply text-white
		*
			@apply text-white
	&.blue
		@apply text-blue-0A2ECC
		*
			@apply text-blue-0A2ECC
	&.normal
		@apply font-normal
		*
			@apply font-normal
.sub-line
	@apply text-grey-333 font-normal leading-tight start-item
	font-size: r(16px)
	align-items: center
	*
		@apply text-grey-333 font-normal 
		font-size: r(16px)
	&::before
		content: ''
		@apply block  pointer-events-none  z-10  transition bg-blue-006BEA
		height: 3px
		width: r(60px)
		margin-right: r(20px)
		min-width: r(60px)
	&.white
		@apply text-white
		*
			@apply text-white