.desc-light
	@apply text-grey-333 font-extralight leading-tight text-center text-xs
	*
		@apply text-grey-333 font-extralight  text-center text-xs
.desc
	@apply text-grey-333 font-normal leading-normal 
	font-size: r(20px)
	strong
		@apply font-bold text-blue-0A2ECC
	*
		@apply text-grey-333 font-normal
		font-size: r(20px)
	p
		@apply mb-4
		@screen lg
			@apply mb-6
		&:last-child
			@apply mb-0
	&.t-16
		font-size: r(16px)
		*
			font-size: r(16px)
	&.t-18
		font-size: r(18px)
		*
			font-size: r(18px)
	&.t-24
		font-size: r(24px)
		*
			font-size: r(24px)
	&.t-36
		@apply text-md
		*
			@apply text-md
	&.white
		@apply text-white
		*
			@apply text-white