.home-rating 
	.block-wrap 
		@apply bg-blue-003675
	.row
		padding: r(85px) 16% r(85px) 8%
		@apply justify-between
		@apply mb-0
		.col
			@apply mb-0
			@media screen and ( max-width: 768px)
				&:first-child
					order: 2
	.icon
		@apply center-item my-5
		@screen lg
			margin: r(23px) 0 r(35px)
	.wrap-item
		@apply flex flex-wrap h-full
		align-items: s
	.item
		@apply w-full 
		@media screen and ( max-width: 576px)
			@apply mt-7
		@screen sm
			@apply w-1/2
		&:nth-child(n+3)
			@screen md
				margin-top: r(50px)
			
	.sub-line
		@apply text-white font-normal 
		margin-bottom: r(20px)
		font-size: 16px
	.headline
		padding-left: r(80px)
		font-size: r(32px)
	.wrap-center
		margin-top: r(30px)
		@screen lg
			margin-top: r(40px)
	.img-wrap
		@apply center-item
		@media screen and ( max-width: 768px)
			@apply mb-8