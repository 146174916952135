.claim-notice-detail 
	.zone-desc
		@apply text-center
		img
			@apply block mx-auto
	.block-wrap
		padding-top: r(30px)
		@screen lg
			padding-top: r(50px)
	.title-wrap
		@apply pb-3
		@screen lg
			@apply pb-4
	.headline
		span
			@apply font-normal
			font-size: r(20px)
	.txt-wrap
		border-bottom: 1px solid #CCCCCC
		padding: 20px 0
		em
			@apply text-grey-333 leading-none inline-block mr-3
			font-size: 18px
			@screen lg
				font-size: 20px
				@apply mr-4
		p
			@apply text-grey-333 font-normal
			font-size: r(16px)
			*
				font-size: r(16px)
		li
			@apply start-item 
			&:not(:last-child)
				@apply mb-3
		h3,h4
			@apply my-3 block font-bold text-grey-333
			font-size: r(20px)
			*
				@apply block font-bold text-grey-333
				font-size: r(20px)
			@screen lg
				padding-left: 35px