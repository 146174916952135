.claim-information
	background: rgba(#E7F1F8, .8)
	.desc
		@apply text-center
.claim-type-information
	.row
		margin: 0 -15px 
		&:not(:last-child)
			margin-bottom: r(60px)
		.col
			padding: 0 15px !important
			margin-bottom: 0 !important
		&:nth-child(even)
			@apply flex-row-reverse
			.txt-wrap
				@screen lg
					@apply pr-8
		&:nth-child(odd)
			.txt-wrap
				@screen lg
					@apply pl-8
	.txt-wrap
		@apply col-hor h-full
		@media screen and ( max-width: 1024px)
			@apply pt-6
	.wrap-left
		@apply mt-4
		@screen lg
			@apply mt-6
	.img-wrap
		a
			+img-cover
	.desc
		@apply leading-normal