.about-story 
	.block-wrap
		@apply relative
	time
		@apply bg-grey-f5 center-item leading-none text-blue-0A2ECC font-bold w-fit mb-4
		border-radius: 15px
		padding: r(8px)  r(20px)
		@screen lg
			@apply mb-5
	.desc
		@apply mb-4
		@screen lg
			@apply mb-5
	.row
		@apply relative
		@media screen and ( max-width: 1024px)
			&:not(:last-child)
				&::after
					content: ''
					@apply block absolute pointer-events-none top-[10px] z-10  transition h-full bg-blue-0A2ECC
					width: 1px
					left: 27px
			@apply mb-0 pb-4
			.col
				@apply mb-0
		@screen lg
			margin: 0 -15px -60px
			.col
				margin-bottom: 60px
	.item
		@screen lg
			&:last-child
				margin-bottom: (50px)
		@media screen and ( max-width: 1024px)
			padding-left: 40px
	.row
		&:not(:last-child)
			.lg\:w-2\/12
				@apply relative
				&::after
					content: ''
					@apply block absolute pointer-events-none top-[10px] z-10  transition h-full bg-blue-0A2ECC
					width: 1px
					right: 35px
					@media screen and ( max-width: 1024px)
						@apply hidden
					
	.years
		@apply text-blue-0A2ECC font-normal leading-none relative w-full z-40
		font-size: r(40px)
		@media screen and ( max-width: 1024px)
			padding-left: 40px
			@apply mb-5
		
		@screen lg
			font-size: r(56px)
		
		
		&::after
			@apply absolute-y
			content: ''
			background-repeat: no-repeat
			background-position: center !important
			appearance: none !important
			box-shadow: none !important
			color: transparent
			width: 24px
			height: 24px
			min-width: 24px
			line-height: 24px
			display: inline-block
			
			background-image: url("data:image/svg+xml;charset=utf-8,<svg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='22' cy='22' r='22' fill='%23ffffff'/><circle cx='22' cy='22' r='19.5' stroke='%230A2ECC'/><circle cx='22' cy='22' r='12' fill='%230A2ECC'/></svg>")
			@screen lg
				width: 40px
				height: 40px
				min-width: 40px
				line-height: 40px
				right: 0
			@media screen and ( max-width: 1024px)
				left: 0
				background-image: url("data:image/svg+xml;charset=utf-8,<svg width='24' height='24' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'><circle cx='22' cy='22' r='22' fill='%23ffffff'/><circle cx='22' cy='22' r='19.5' stroke='%230A2ECC'/><circle cx='22' cy='22' r='12' fill='%230A2ECC'/></svg>")