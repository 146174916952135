.financial-report
	.top-nav-wrap
		@apply mb-3
		@media screen and ( max-width: 1024px)
			+res-nav
	.block-wrap
		h3
			@apply text-blue-0A2ECC font-bold my-4
	.block-content
		&:not(:last-child)
			@apply mb-5
			@screen lg
				margin-bottom: r(40px)
	.second-block
		margin-top: r(40px)
	.table-wrap 
		thead
			th
				width: 16%
				&:first-child
					width: 8%
				&:nth-child(2)
					width: 24%
				&:nth-child(3)
					@apply w-auto
				&:nth-child(n+4)
					@apply text-center
		tbody
			td
				width: 16%
				&:first-child
					width: 8%
				&:nth-child(2)
					width: 24%
				&:nth-child(3)
					@apply w-auto
				&:nth-child(n+4)
					@apply text-center
					