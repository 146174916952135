.credit-rating-list
	.block-wrap
		@apply border-t-2 border-dark
		padding-top: 20px 
		margin-top: r(20px)
		@screen sm
			paddingp-top: 30px 0
			margin-top: r(30px)
		@screen lg
			margin-top: r(60px)
		h3
			@apply text-blue-0A2ECC font-bold my-4
		&.achievement-wrapper
			.table-wrap 
				thead
					th
						width: auto
						&:first-child
							width: 16%
				tbody
					td
						width: auto
						&:first-child
							width: 16%

	.top-nav-wrap
		@media screen and ( max-width: 576px)
			+res-nav
		h3,h4
			@apply text-blue-0A2ECC font-bold 
			font-size: r(18px)
	.table-wrap 
		thead
			th
				width: 16%
				&:last-child
					@apply w-auto
		tbody
			td
				width: 16%
				&:last-child
					@apply w-auto
