=clamp($font-size, $line-height, $lines-to-show)
	@if unitless($line-height) == false 
		$line-height: create-unitless-line-height($font-size, $line-height)
	display: block
	display: -webkit-box
	font-size: ($font-size/1920px*100rem)
	height: (($font-size/1920px*100rem) * $line-height * $lines-to-show) 
	line-height: $line-height
	overflow: hidden
	text-overflow: ellipsis
	-webkit-box-orient: vertical
	-webkit-line-clamp: $lines-to-show
=line($line)
	display: -webkit-box
	-webkit-line-clamp:  $line
	-webkit-box-orient: vertical  
	overflow: hidden
=img-ratio($ratio,$fit:cover)
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top: $ratio *100%
	img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: $fit
		transition: .3s ease-in-out all
=img-cover
	position: relative
	display: block
	height: 0
	overflow: hidden
	padding-top:  100%
	img
		position: absolute
		width: 100%
		height: 100%
		top: 0
		left: 0
		object-fit: cover
		transition: .3s ease-in-out all
=res-nav
	@apply overflow-x-auto
	ul
		@apply start-item whitespace-nowrap pb-1
		justify-content: flex-start
		li
			&:first-child
				@apply pl-0

=boxshadow
	box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.09)
