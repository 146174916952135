html 
	-webkit-text-size-adjust: 100% 
	box-sizing: border-box 
	font-family: sans-serif 
	font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"
	line-height: 1.2
	scroll-behavior: smooth
body 
	margin: 0 10px
main 
	display: block 
	
h1 
	font-size: 2em 
	margin: 0.67em 0 
hr 
	box-sizing: content-box 
	height: 0 
	overflow: visible 
	border-top-width: 1px 
pre 
	font-family: monospace, monospace 
	font-size: 1em 
a 
	background-color: transparent 
	color: inherit 
	text-decoration: inherit 
abbr[title] 
	border-bottom: none 
	text-decoration: underline 
	-webkit-text-decoration: underline dotted 
	text-decoration: underline dotted 
b,
strong 
	font-weight: bolder 
code,
kbd,
samp 
	font-family: monospace, monospace 
	font-size: 1em 
small 
	font-size: 80% 
sub,
sup 
	font-size: 75% 
	line-height: 0 
	position: relative 
	vertical-align: baseline 
sub 
	bottom: -0.25em 
sup 
	top: -0.5em 
img 
	border-style: none 
	border-style: solid
	max-width: 100%
	height: auto
button,
input,
optgroup,
select,
textarea 
	font-family: inherit 
	font-size: 100% 
	line-height: 1.2
	margin: 0 
	padding: 0 
	line-height: inherit 
	color: inherit 
button,
input 
	overflow: visible 
button,
select 
	text-transform: none 
button,
[type="button"],
[type="reset"],
[type="submit"] 
	-webkit-appearance: button 
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner 
	border-style: none 
	padding: 0 
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring 
	outline: 1px dotted ButtonText 
fieldset 
	padding: 0.35em 0.75em 0.625em 
	margin: 0 
	padding: 0 
legend 
	box-sizing: border-box 
	color: inherit 
	display: table 
	max-width: 100% 
	padding: 0 
	white-space: normal 
progress 
	vertical-align: baseline 
textarea 
	overflow: auto 
	resize: vertical 
[type="checkbox"],
[type="radio"] 
	box-sizing: border-box 
	padding: 0 
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button 
	height: auto 
[type="search"] 
	-webkit-appearance: textfield 
	outline-offset: -2px 
[type="search"]::-webkit-search-decoration 
	-webkit-appearance: none 
::-webkit-file-upload-button 
	-webkit-appearance: button 
	font: inherit 
details 
	display: block 
summary 
	display: list-item 
template 
	display: none 
[hidden] 
	display: none 
*,
*::before,
*::after 
	box-sizing: border-box 
	border-width: 0 
	border-style: solid 
	border-color: #e2e8f0 
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre 
	margin: 0 
button 
	background: transparent 
	padding: 0 
	&:focus 
		outline: 1px dotted 
		outline: 5px auto -webkit-focus-ring-color 
ol,
ul 
	list-style: none 
	margin: 0 
	padding: 0 
input:-ms-input-placeholder, textarea:-ms-input-placeholder 
	color: #a0aec0 
input::-ms-input-placeholder, textarea::-ms-input-placeholder 
	color: #a0aec0 
input::placeholder,
textarea::placeholder 
	color: #a0aec0 
button,
[role="button"] 
	cursor: pointer 
table 
	border-collapse: collapse 
	width: 100%
h1,
h2,
h3,
h4,
h5,
h6 
	font-size: inherit 
	font-weight: inherit 
pre,
code,
kbd,
samp 
	font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace 
img,
svg,
video,
canvas,
audio,
iframe,
embed,
object 
	display: block 
	vertical-align: middle 
img,
video 
	max-width: 100% 
	height: auto 
section
	display: block
footer
	@apply flex flex-col

